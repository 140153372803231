const styles = (theme) => ({
  container: {
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#013870",
    position: "fixed",
    top: "0",
    zIndex: 1203,
  },
  navIcon: {
    width: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  rightContent: {
    width: "80%",
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
  },
  navLinksActive: {
    padding: "0px 15px 0px 15px",
    color: "white",
    borderBottom: "2px solid green",
    cursor: "pointer",
  },
  navLinks: {
    padding: "0px 15px 0px 15px",
    color: "white",
    cursor: "pointer",
  },
  postAProposalContainer: {
    cursor: "pointer",
    padding: "0px 15px 0px 15px",
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  postAProposalBtn: {
    padding: "5px",
    borderRadius: "5px",
    border: "2px solid white",
  },
  logOut: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "orange",
    color: "white",
    height: "70%",
    marginTop: "5px",
    borderRadius: "5px",
    padding: "2px 5px 2px 5px",
    marginRight: "30px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  signIn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "green",
    color: "white",
    height: "70%",
    marginTop: "5px",
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    marginRight: "30px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "green",
    },
  },
});
export default styles;
