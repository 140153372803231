import {
  collapseClasses,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page } from "react-pdf";
import styles from "../../../resources/styles/helpers-styles/viewRFQStyles";
import {
  askQuestion,
  getRFQs,
  patchRFQs,
  postAnAnswer,
  postRFQBid,
} from "../../../store/entities/rfqs";
import configData from "../../../config.json";
import FileDownload from "js-file-download";
import { useNavigate } from "react-router-dom";
import { authenticateAlreadySignedInUser } from "../../../store/auth/auth";

function ViewRFQ(props) {
  const { classes } = props;

  const rfqs = useSelector((state) => state.entities.rfqs.rfqs);
  const user = useSelector((state) => state.auth.user);

  const isRefreshRFQs = useSelector(
    (state) => state.entities.rfqs.isRefreshRFQs
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rfqData, setRFQ] = useState({});
  const [RFQFile, setRFQFile] = useState(null);
  const [openBiddingModal, setOpenBiddingModal] = useState(false);
  const [isOpenAskQuestion, setIsOpenAskQuestion] = useState(false);
  const [isOpenConfirmRFQClose, setIsOpenConfirmRFQClose] = useState(false);
  const [isOpenAnswerQuestion, setIsOpenAnswerQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [bidAmount, setBidAmount] = useState("");

  const handleDownloadFile = () => {
    axios
      .get(
        configData.urls.baseURL +
          configData.urls.rfqUrl +
          configData.urls.rfqFileUrl +
          "/" +
          rfqData?.id,
        {
          headers: {
            authorization: `bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        FileDownload(res.data, `${rfqData.title}.pdf`);
      });
  };

  useEffect(() => {
    if (isRefreshRFQs) {
      dispatch(getRFQs());
    }
  }, [isRefreshRFQs]);

  useEffect(() => {
    setRFQ(
      rfqs.filter(
        (rfq) =>
          rfq.id ===
          window.location.pathname.split("/")[
            window.location.pathname.split("/").length - 1
          ]
      )[0]
    );
    // if (Object.keys(rfqData).length) {
    //   axios
    //     .get(
    //       configData.urls.baseURL +
    //         configData.urls.rfqUrl +
    //         configData.urls.rfqFileUrl +
    //         "/" +
    //         rfqData?.id,
    //       {
    //         headers: {
    //           authorization: `bearer ${localStorage.getItem("token")}`,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(res.data);
    //       reader.onload = () => setRFQFile(reader.result);
    //     });
    // }
  }, [rfqs]);

  useEffect(() => {
    if (rfqs.length === 0) {
      dispatch(getRFQs());
    }
    dispatch(authenticateAlreadySignedInUser(localStorage.getItem("token")));
  }, []);

  const renderQAs = (qasData) => {
    return (
      <>
        {qasData?.map((qaData) => (
          <div className={classes.qaContainer}>
            <div className={classes.question}>Question: {qaData.question}</div>
            {qaData.answer.length > 0 ? (
              <div className={classes.answer}>Answer: {qaData.answer}</div>
            ) : user?.role?.shortCode === "issur" ? (
              <div
                className={classes.postAnAnswer}
                onClick={() => {
                  setIsOpenAnswerQuestion(true);
                  setSelectedQuestion(qaData);
                }}
              >
                Answer this question?
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </>
    );
  };

  const checkAlreadyBid = () => {
    console.log(
      rfqData.bids.filter((bid) => bid.userID === user.id).length > 0,
      user.id,
      rfqData.bids
    );
    if (rfqData.bids.filter((bid) => bid.userID === user.id).length > 0) {
      return true;
    } else return false;
  };

  return (
    <div className={classes.container}>
      {rfqs.length !== 0 ? (
        <>
          <div className={rfqData?.status ? classes.active : classes.inActive}>
            {rfqData?.status ? "Active" : "In Active"}
          </div>

          <div className={classes.title}>{rfqData?.title}</div>
          <div className={classes.keywords}>{rfqData?.keywords}</div>
          <div className={classes.halfContainer}>
            <div className={classes.content}>
              <div className={classes.onBehalfOf}>
                On Behalf Of : {rfqData?.organization}
              </div>
              <div className={classes.category}>{rfqData?.category}</div>
              <div className={classes.budget}>{rfqData?.budget}</div>
              <div className={classes.responsesDue}>
                Responses Due | {rfqData?.submissionDeadline}
              </div>
              <div className={classes.timeline}>
                Timeline | {rfqData?.approximateStartDate} -{" "}
                {rfqData?.approximateEndDate}
              </div>
            </div>
            <div className={classes.buttons}>
              {console.log(user.role.shortCode)}
              <div
                className={
                  rfqData?.status && user?.role?.shortCode !== "issur"
                    ? classes.submitAResponse
                    : classes.submitAResponseDisabled
                }
                onClick={() => {
                  if (user?.role?.shortCode === "bdr") {
                    navigate("/pricing-and-plan");
                  } else if (
                    rfqData?.status &&
                    user?.role?.shortCode !== "issur"
                  ) {
                    if (!checkAlreadyBid()) {
                      setOpenBiddingModal(!openBiddingModal);
                    } else alert("Already Bid");
                  }
                }}
              >
                Submit a Response
              </div>
              <div
                className={classes.download}
                onClick={() => {
                  handleDownloadFile();
                }}
              >
                Download
              </div>
              <div
                className={
                  rfqData?.status && user?.role?.shortCode !== "issur"
                    ? classes.askQuestion
                    : classes.askQuestionDisabled
                }
                onClick={() => {
                  if (rfqData?.status && user?.role?.shortCode !== "issur")
                    setIsOpenAskQuestion(!isOpenAskQuestion);
                }}
              >
                Ask a question?
              </div>
              {user?.role?.shortCode === "adm" ||
              user?.role?.shortCode === "issur" ? (
                <div
                  className={classes.markAsClosed}
                  onClick={() => {
                    setIsOpenConfirmRFQClose(!isOpenConfirmRFQClose);
                  }}
                >
                  {user?.role?.shortCode === "adm"
                    ? rfqData.status
                      ? `Mark RFQ as closed`
                      : `Mark RFQ as opened`
                    : `Mark RFQ as closed`}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={classes.summaryTitle}>Summary</div>
          <div className={classes.summaryContent}>{rfqData?.summary}</div>
          <div className={classes.qasContainer}>{renderQAs(rfqData?.qnas)}</div>
          {/* <div className={classes.pdfFile}>
            {console.log(RFQFile)}
            <Document file={RFQFile}></Document>
          </div> */}
        </>
      ) : (
        <div>Loading</div>
      )}
      <Dialog open={openBiddingModal}>
        <DialogTitle>Submit a response on quotation</DialogTitle>
        <DialogContent>
          <div className={classes.dialogContainer}>
            <label className={classes.label}> Bidding Amount</label>
            <input
              className={classes.input}
              type={"text"}
              onChange={(e) => {
                setBidAmount(e.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div
            className={classes.submit}
            onClick={() => {
              setOpenBiddingModal(false);
              dispatch(postRFQBid(rfqData.id, bidAmount));
              navigate("/marketplace");
            }}
          >
            Submit
          </div>
          <div
            className={classes.cancel}
            onClick={() => setOpenBiddingModal(false)}
          >
            Cancel
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpenAskQuestion} fullWidth>
        <DialogTitle>Ask a Question?</DialogTitle>
        <DialogContent>
          <div className={classes.dialogContainer}>
            <label className={classes.label}> Question</label>
            <textarea
              className={classes.inputArea}
              type={"text"}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div
            className={classes.submit}
            onClick={() => {
              setIsOpenAskQuestion(false);
              dispatch(
                askQuestion({
                  rfq: rfqData.id,
                  question,
                  answer: "",
                  from: user.id,
                  to: rfqData.userId,
                })
              );
              // dispatch(postRFQBid(rfqData.id, bidAmount));
              // navigate("/marketplace");
            }}
          >
            Submit
          </div>
          <div
            className={classes.cancel}
            onClick={() => setIsOpenAskQuestion(false)}
          >
            Cancel
          </div>
        </DialogActions>
      </Dialog>

      <Dialog open={isOpenConfirmRFQClose}>
        <DialogTitle>
          {rfqData.status
            ? "Are you sure to mark rfq as closed"
            : "Are you sure to mark rfq as opened"}
        </DialogTitle>

        <DialogActions>
          <div
            className={classes.submit}
            onClick={() => {
              setIsOpenConfirmRFQClose(false);
              dispatch(
                patchRFQs(rfqData.id, {
                  status:
                    user?.role?.shortCode === "adm" ? !rfqData.status : false,
                })
              );
            }}
          >
            Submit
          </div>
          <div
            className={classes.cancel}
            onClick={() => setIsOpenConfirmRFQClose(false)}
          >
            Cancel
          </div>
        </DialogActions>
      </Dialog>

      <Dialog open={isOpenAnswerQuestion}>
        <DialogTitle>Answer a question</DialogTitle>
        <DialogContent>
          <div className={classes.qaContainer}>
            <div className={classes.question}>
              Question: {selectedQuestion.question}
            </div>
            <div className={classes.answer}>
              Answer:{" "}
              <textarea
                className={classes.inputArea}
                type={"text"}
                onChange={(e) => {
                  setAnswer(e.target.value);
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div
            className={classes.submit}
            onClick={() => {
              setIsOpenAnswerQuestion(false);

              dispatch(postAnAnswer(selectedQuestion.id, { answer }));
              // dispatch(postRFQBid(rfqData.id, bidAmount));
              // navigate("/marketplace");
            }}
          >
            Submit
          </div>
          <div
            className={classes.cancel}
            onClick={() => setIsOpenAnswerQuestion(false)}
          >
            Cancel
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(ViewRFQ);
