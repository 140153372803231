import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import styles from "../../../resources/styles/common-styles/RFQMarketplaceTile";

function RFQMarketplaceTile(props) {
  const { classes } = props;
  const {
    id,
    title,
    summary,
    organization,
    category,
    submissionDeadline,
    approximateStartDate,
    approximateEndDate,
    estimatedHoursPerWeek,
    budget,
    keywords,
    bidCount,
    status,
  } = props;
  const navigate = useNavigate();
  return (
    <div
      className={classes.container}
      onClick={() => {
        navigate(`/viewRFQ/${id}`);
      }}
    >
      <div
        className={status ? classes.rfqStatusActive : classes.rfqStatusInActive}
      >
        {status ? "Active" : "In Active"}
      </div>
      <div className={classes.title}> {title}</div>
      <div className={classes.summary}>{summary.slice(0, 250)}...</div>
      <div className={classes.keywords}>{keywords}</div>
      <div className={classes.budget}>{budget}</div>
      <div className={classes.category}>{category}</div>
      <div className={classes.timeline}>
        {"Timeline | " + approximateStartDate + " - " + approximateEndDate}
      </div>
      <div className={classes.dueOn}>
        {"Due on : " + submissionDeadline}
        {estimatedHoursPerWeek.length
          ? " approx " + estimatedHoursPerWeek + " hrs per week"
          : ""}
      </div>

      <div className={classes.bidCountContainer}>
        Bid Count: <div className={classes.bidCount}>{bidCount}</div>
      </div>
    </div>
  );
}
export default withStyles(styles)(RFQMarketplaceTile);
