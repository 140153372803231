import { withStyles } from "@mui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "../../../resources/styles/helpers-styles/settingsStyles";

import configData from "../../../config.json";

import { useNavigate } from "react-router-dom";
import { authenticateAlreadySignedInUser } from "../../../store/auth/auth";

function Settings(props) {
  const { classes } = props;
  useEffect(() => {
    dispatch(authenticateAlreadySignedInUser(localStorage.getItem("token")));
  }, []);

  const user = useSelector((state) => state.auth.user);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    let data = {};
    if (password.length > 0 && confirmPassword.length > 0) {
      data = { email: user.email, password, updatePassword: confirmPassword };
    }
    // if (sendEmail) {
    //   data = { sendEmail };
    // }
    // if ((password.length > 0 && confirmPassword.length > 0) || sendEmail) {
    //   data = { sendEmail, password, updatePassword: confirmPassword };
    // }

    axios
      .patch(
        configData.urls.baseURL +
          configData.urls.authentication +
          configData.urls.updateCredentials,
        data,
        {
          headers: {
            Authentication: `bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setSuccess(true);
        setSuccessMessage("Password changed successfully!");
        setTimeout(() => {
          setSuccessMessage("");
        }, 6000);
      })
      .catch((e) => {
        setError(e.response.data.message);
        setTimeout(() => {
          setError("");
        }, 6000);
      });
  };

  return (
    <div className={classes.container}>
      <div style={{ color: "orange" }}>{error}</div>
      <div style={{ color: "green" }}>{successMessage}</div>
      <div className={classes.settingsCard}>
        <div>Change Password</div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Current Password</div>
          <input
            type={"password"}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>New Password</div>
          <input
            type={"password"}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            value={confirmPassword}
            className={classes.inputValue}
          />
        </div>
      </div>
      {/* <div className={classes.settingsCard}>
        <div>Send Email?</div>

        <div className={classes.row}>
          <div className={classes.inputLabel}>Allow us to send you emails?</div>
          <input
            type={"checkbox"}
            onChange={(e) => {
              setSendEmail(!sendEmail);
            }}
            value={sendEmail}
            className={classes.inputValue}
          />
        </div>
      </div> */}
      <div className={classes.settingsCard}>
        <div className={classes.saveButton} onClick={handleSubmit}>
          Save
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Settings);
