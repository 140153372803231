import { withStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";

import styles from "../../../resources/styles/helpers-styles/addRFQStyles";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../store/entities/categories";
import configData from "./../../../config.json";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useNavigate } from "react-router-dom";

function AddRFQ(props) {
  const { classes } = props;
  const { openAlert, handleCancel, handleYes } = props;

  const baseUrl = configData.urls.baseURL;
  const postQuotation = configData.urls.rfqUrl;
  const url = `${baseUrl}${postQuotation}`;

  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [organization, setOrganization] = useState("");
  const [category, setCategory] = useState("");
  const [submissionDeadline, setSubmissionDeadline] = useState("");
  const [approximateStartDate, setApproximateStartDate] = useState("");
  const [approximateEndDate, setApproximateEndDate] = useState("");
  const [estimatedHoursPerWeek, setEstimatedHoursPerWeek] = useState("");
  const [budget, setBudget] = useState("");
  const [keywords, setKeywords] = useState("");
  const [keywordsArray, setKeywordsArray] = useState([]);
  const [additionalInformation, setAdditionalInformation] = useState([]);

  const fileRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector(
    (state) => state.entities.categories.categories
  );

  const authToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (categories.length === 0) dispatch(getCategories());
  }, []);

  const renderCategories = () => {
    const list = categories.map((cat) => (
      <option key={cat.id} value={cat.category}>
        {cat.category}
      </option>
    ));
    return list;
  };

  const handleKeyUp = (e) => {
    if (keywordsArray.length >= 5) return;
    if (e.key == "Enter" || e.keyCode === 13) {
      const trimmedValue = e.target.value.trim().replaceAll(" ", "-");
      setKeywordsArray([...keywordsArray, trimmedValue]);
      setKeywords("");
    }
  };

  const removeKeyword = (idx) => () => {
    let resultArr = [...keywordsArray];
    resultArr.splice(idx, 1);
    setKeywordsArray([...resultArr]);
  };

  const renderKeyWords = () => {
    const list = keywordsArray.map((word, idx) => (
      <div className={classes.keyword} key={idx}>
        <p>{word}</p>
        <CloseIcon onClick={removeKeyword(idx)} />
      </div>
    ));
    return list;
  };

  const getKeywordsString = () => {
    let stringArr = keywordsArray[0];
    for (let i = 1; i < keywordsArray.length; i++) {
      stringArr += " " + keywordsArray[i];
    }
    return stringArr;
  };

  const resetForm = () => {
    setFile(null);
    setTitle("");
    setSummary("");
    setOrganization("");
    setCategory(null);
    setSubmissionDeadline("");
    setApproximateStartDate("");
    setApproximateEndDate("");
    setEstimatedHoursPerWeek("");
    setBudget("");
    setKeywords("");
    setKeywordsArray([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", file);
    data.append("title", title);
    data.append("summary", summary);
    data.append("organization", organization);
    data.append("category", category);
    data.append("submissionDeadline", submissionDeadline);
    data.append("approximateStartDate", approximateStartDate);
    data.append("approximateEndDate", approximateEndDate);
    data.append("estimatedHoursPerWeek", estimatedHoursPerWeek);
    data.append("budget", budget);
    data.append("keywords", keywords);
    data.append("additionalInformation", "{}");
    var config = {
      method: "post",
      url,
      headers: {
        authorization: "Bearer " + authToken,
        // ...data.getHeaders(),
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resetForm();
        navigate("/marketplace");
      })
      .catch(function (error) {
        console.log(error);
      });
    // var FormData = require("form-data");
    // var data = new FormData();
    // handleYes(data);
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <div className={classes.formControl}>
          <label className={classes.formLabel}>Document Upload</label>
          <input
            type={"file"}
            accept="application/pdf"
            style={{ display: "none" }}
            ref={fileRef}
            onChange={() => setFile(fileRef.current.files[0])}
          />
          <div
            className={classes.formFileInput}
            onClick={() => fileRef.current.click()}
          >
            <p>{file ? file.name : "No File Selected"}</p>
            <AttachFileIcon />
          </div>
        </div>
        <div className={classes.formControl}>
          <label className={classes.formLabel}>Title</label>
          <input
            type={"text"}
            className={classes.formInput}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <p>
            Post a descriptive title, for example: Communications Consultant for
            Ocean Conservation Initiative
          </p>
        </div>
        <div className={classes.formControl}>
          <label className={classes.formLabel}>Summary</label>
          <textarea
            type={"text"}
            className={classes.formInput}
            rows="2"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          />
        </div>

        {/*first row container*/}
        <div className={classes.rowInputsContainer}>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Organization</label>
              <input
                type={"text"}
                className={classes.formInput}
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Project Category</label>
              <select
                className={classes.formSelect}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value={null}>Select a Category</option>
                {renderCategories()}
              </select>
            </div>
          </div>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Submission Deadline</label>
              <input
                type={"date"}
                className={classes.formInput}
                value={submissionDeadline}
                onChange={(e) => setSubmissionDeadline(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/**Second Row COntainer */}
        <label className={classes.formLabel}>Project Length</label>
        <div className={classes.rowInputsContainer}>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>
                Approximate Start Date
              </label>
              <input
                type={"date"}
                className={classes.formInput}
                value={approximateStartDate}
                onChange={(e) => setApproximateStartDate(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Approximate End Date</label>
              <input
                type={"date"}
                className={classes.formInput}
                value={approximateEndDate}
                onChange={(e) => setApproximateEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>
                Estimated Hours per Week
              </label>
              <input
                type={"tel"}
                className={classes.formInput}
                value={estimatedHoursPerWeek}
                onChange={(e) => setEstimatedHoursPerWeek(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* <label className={classes.formLabel}>Budget</label> */}
        <div className={classes.formControl}>
          <label className={classes.formLabel}>Enter Your Budget</label>
          <input
            type={"text"}
            className={classes.formInput}
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
          />
        </div>

        <div className={classes.formControl}>
          {/* <label className={classes.formLabel}>Keywords (5 Maximum)</label>
          <div className={classes.keywordsContainer}>{renderKeyWords()}</div> */}
          <label className={classes.formLabel}>Keywords</label>
          <input
            type={"text"}
            className={classes.formInput}
            // onKeyUp={handleKeyUp}
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
          {/* <p>
            Hit ENTER after typing in each keyword (e.g. DigitalMarketing,
            BIPOC-Owned, Java)
          </p> */}
        </div>

        <div className={classes.formControl}>
          <button type={"submit"} className={classes.submit}>
            Post Quotation
          </button>
        </div>
      </form>
    </div>
  );
}

export default withStyles(styles)(AddRFQ);
