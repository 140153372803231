import { borderRadius } from "@mui/system";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    margin: "20px 10px 0px 10px",
    padding: "10px",
    borderRadius: "5px",
    border: "#013870",
    boxShadow: "rgba(1, 56, 112, 0.3) 0px 7px 10px 2px",
    width: "300px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#a8cff7",
    },
  },
  title: {
    color: "#013870",
    fontSize: "18px",
    fontWeight: "600",
  },
  summary: {
    marginTop: "10px",
    color: "#013870",
    fontSize: "15px",
    fontWeight: "400",
  },
  keywords: {
    color: "grey",
    marginTop: "10px",
    fontSize: "15px",
    fontWeight: "400",
    fontStyle: "italic",
  },
  budget: {
    marginTop: "10px",
    color: "#013870",
    fontSize: "20px",
    fontWeight: "700",
  },
  category: {
    marginTop: "10px",
    fontSize: "15px",
    fontWeight: "400",
  },
  timeline: {
    marginTop: "10px",
    color: "#013870",
    fontSize: "15px",
    fontWeight: "400",
  },
  dueOn: {
    marginTop: "10px",
    color: "green",
    fontSize: "15px",
    fontWeight: "400",
  },
  bidCountContainer: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bidCount: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "green",
    borderRadius: "50%",
    padding: "5px",
    width: "20px",
    color: "white",
    alignItems: "center",
  },
  rfqStatusActive: {
    backgroundColor: "green",
    color: "white",
    borderRadius: "50px",
    width: "25%",
    textAlignLast: "center",
    margin: "10px 0px 10px 0px",
  },
  rfqStatusInActive: {
    margin: "10px 0px 10px 0px",
    backgroundColor: "orange",
    color: "white",
    borderRadius: "50px",
    width: "25%",
    textAlignLast: "center",
  },
});
export default styles;
