const styles = (theme) => ({
  container: {
    margin: "20px",
    marginTop: "60px",
  },
  categoriesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  category: {
    cursor: "pointer",
    color: "#013870",
    margin: "5px",
    padding: "5px",
    width: "200px",
    border: "1px solid #013870",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  categoryActive: {
    cursor: "pointer",
    color: "white",
    backgroundColor: "#013870",
    margin: "5px",
    padding: "5px",
    width: "200px",
    border: "1px solid #013870",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rfqContainer: {
    marginTop: "20px",
    display: "flex",
    flexWrap: "wrap",
  },
});
export default styles;
