import { withStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../resources/styles/helpers-styles/RfqsTabstyles";
import { getOpenRFQs } from "../../../store/entities/rfqs";
import RFQMarketplaceTile from "./RFQMarketplaceTile";
import loadingImage from "./../../../resources/design-images/tab-loading.gif";

function OpenRfqsTab(props) {
  const { classes } = props;
  const dispatch = useDispatch();

  const rfqs = useSelector((state) => state.entities.rfqs.openRfqs);
  const rfqsLoading = useSelector((state) => state.entities.rfqs.loading);

  useEffect(() => {
    if (rfqs.length === 0) dispatch(getOpenRFQs());
  }, []);

  const renderRfqs = () => {
    if (rfqs.length === 0) return <p>No RFQs to Show.</p>;
    const list = rfqs.map((rfq) => (
      <RFQMarketplaceTile
        id={rfq.id}
        title={rfq.title}
        summary={rfq.summary}
        organization={rfq.organization}
        category={rfq.category}
        submissionDeadline={rfq.submissionDeadline}
        approximateStartDate={rfq.approximateStartDate}
        approximateEndDate={rfq.approximateEndDate}
        estimatedHoursPerWeek={rfq.estimatedHoursPerWeek}
        budget={rfq.budget}
        keywords={rfq.keywords}
        bidCount={rfq.bids.length}
        status={rfq.status}
      />
    ));
    return list;
  };

  return (
    <div className={classes.root}>
      {rfqsLoading ? (
        <div className={classes.loading}>
          <img src={loadingImage} />
        </div>
      ) : (
        renderRfqs()
      )}
    </div>
  );
}

export default withStyles(styles)(OpenRfqsTab);
