import { withStyles } from "@mui/styles";
import styles from "../../../resources/styles/website-styles/websiteStyles";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import proposal from "../../../resources/design-images/proposal.gif";
import managing from "../../../resources/design-images/managing.gif";
import chatting from "../../../resources/design-images/chatting.gif";
import paperwork from "../../../resources/design-images/paperwork.gif";
import rfqBaseIcon from "../../../resources/design-icons/rfqbase.png";

function WebsiteLandingPage(props) {
  const { classes } = props;

  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.content}>
          <div className={classes.largeFont}>
            Join the inclusive RFQ Marketplace
          </div>
          <div className={classes.mediumFont}>
            RFQ base consolidates your RFQ process into one simple platform
          </div>
          <div className={classes.content}>
            <div className={classes.mediumFont}>Lets explore marketplace?</div>
            <div className={classes.row}>
              <div
                className={classes.signUp}
                onClick={() => {
                  navigate("/signup");
                }}
              >
                {" "}
                Join Us
              </div>
              <div
                className={classes.signIn}
                onClick={() => {
                  navigate("/signin");
                }}
              >
                {" "}
                Already a member
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src={proposal} alt={"Proposal"} />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.largeFont}>Why RFQ Base?</div>
      </div>
      <div className={classes.row}>
        <div className={classes.mediumFont}>
          We provide an easy way to managing your Quotations
        </div>
        <div>
          <img src={managing} alt={"Managing"} />
        </div>
      </div>
      <div className={classes.row}>
        <div>
          <img src={paperwork} alt={"Paperwork"} />
        </div>
        <div className={classes.mediumFont}>
          No need for traditional paper work
        </div>{" "}
      </div>
      <div className={classes.row}>
        <div className={classes.mediumFont}>
          Shared space for both Bidders and Issuers for crystal clear
          communications
        </div>{" "}
        <div>
          <img src={chatting} alt={"Chatting"} />
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.footerContainer}>
          <div className={classes.row}>
            <div className={classes.largeFont}>
              Change the tradition of RFQ Bidding by{" "}
            </div>
            <img src={rfqBaseIcon} alt={"RFQBase"} />
          </div>
          <div className={classes.rowSpaceBetween}>
            <>
              <div className={classes.smallFont}>Privacy Policy</div>
              <div className={classes.smallFont}>Terms of service</div>
            </>
            <div className={classes.smallFont}>
              © 2022. RFQ Base. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withStyles(styles)(WebsiteLandingPage);
