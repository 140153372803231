export const expertiseOptions = [
  { value: "website", text: "Website" },
  { value: "technology", text: "Technology" },
  { value: "finance", text: "Finance" },
  { value: "strategic-planning", text: "Strategic Planning" },
  { value: "communications", text: "Communications" },
  { value: "marketing", text: "Marketing" },
  { value: "social-media", text: "Social Media" },
  { value: "fundraising", text: "Fundraising" },
  { value: "digital-media", text: "Digital Media" },
  { value: "data-&-analysis", text: "Data & Analysis" },
  {
    value: "diversity-equity-&-inclusion",
    text: "Diversity, Equity & Inclusion",
  },
  { value: "government-affairs", text: "Government Affairs" },
  { value: "legal", text: "Legal" },
  { value: "business-development", text: "Business Development" },
  {
    value: "translation-&-interpretation",
    text: "Translation & Interpretation",
  },
  { value: "branding-&-design", text: "Branding & Design" },
  { value: "other", text: "Other" },
];

export const languagesOptions = [
  { value: "english", text: "English" },
  { value: "chinese", text: "Chinese" },
  { value: "spanish", text: "Spanish" },
  { value: "arabic", text: "Arabic" },
  { value: "portuguese", text: "Portuguese" },
  { value: "russian", text: "Russian" },
  { value: "japanese", text: "Japanese" },
  { value: "french", text: "French" },
  { value: "indonesian", text: "Indonesian" },
];
