import { withStyles } from "@mui/styles";
import styles from "../../resources/styles/helpers-styles/SignIn";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authenticateUser } from "../../store/auth/auth";
import { useNavigate } from "react-router-dom";
import loader from "../../resources/design-images/loader.gif";
import CircularProgress from "@mui/material/CircularProgress";
import { LinearProgress } from "@mui/material";
import rfqbaseicon from "../../resources/design-icons/rfqbase.png";
function SignIn(props) {
  const { classes } = props;
  const [emailAddress, setEmailAddress] = useState(
    localStorage.getItem("emailAddress")
  );
  const [password, setPassword] = useState(localStorage.getItem("password"));
  const [rememberMe, setRememberMe] = useState(false);

  const disptach = useDispatch();
  const isLoading = useSelector((state) => state.auth.loading);
  const user = useSelector((state) => state.auth.user);
  const authError = useSelector((state) => state.auth.isError);
  const authErrorMsg = useSelector((state) => state.auth.errorMessage);
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("rememberMe") === "true") {
      disptach(
        authenticateUser(
          localStorage.getItem("emailAddress"),
          localStorage.getItem("password")
        )
      );
    }
  }, []);
  useEffect(() => {
    if (Object.keys(user).length) {
      if (user?.role?.shortCode === "adm") navigate("/admin");
      else navigate("/marketplace");
    }
  }, [user]);
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.row}>
          <div className={classes.headingTextBig}>Welcome!</div>
        </div>
        <div className={classes.row}>
          <div className={classes.headingTextMedium}>
            Please sign in to continue!
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.headingTextMedium} style={{ color: "red" }}>
            {authError ? authErrorMsg?.message : ""}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Email Address</div>
          <input
            type={"text"}
            onChange={(e) => {
              setEmailAddress(e.target.value);
            }}
            value={emailAddress}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Password</div>
          <input
            type={"password"}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.rowShort}>
          <input
            type={"checkbox"}
            onChange={(e) => {
              setRememberMe(!rememberMe);
            }}
            value={password}
            className={classes.inputValueMargin}
          />
          <div>Remember Me?</div>
        </div>
        <div className={classes.row}>
          <div className={classes.LoginButtonContainer}>
            <div
              className={classes.LoginButton}
              onClick={() => {
                disptach(authenticateUser(emailAddress, password));
                if (rememberMe) {
                  localStorage.setItem("rememberMe", rememberMe);
                  localStorage.setItem("emailAddress", emailAddress);
                  localStorage.setItem("password", password);
                }
              }}
            >
              {!isLoading ? (
                "Signin"
              ) : (
                <img src={loader} className={classes.loader} alt={"loader"} />
              )}
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <div
            className={classes.alreadyAMember}
            onClick={() => {
              navigate("/signup");
            }}
          >
            Not a member?
          </div>
        </div>
      </div>
    </div>
  );
}
export default withStyles(styles)(SignIn);
