import backgroundSVG from "../../design-images/background.svg";
const styles = (theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    backgroundImage: `url("${backgroundSVG}")`,
  },
  content: {
    backgroundColor: "white",
    boxShadow: "#03bafc 0px 7px 29px 0px",
    padding: "10px",
    borderRadius: "10px",
    width: "65%",
    // height: "80%",
    // overflowY: "auto",

    "@media (min-width: 1300px)": {
      width: "25%",
    },
  },
  row: {
    margin: "15px 5px 15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rowShort: {
    margin: "15px 5px 15px",
    display: "flex",
    alignItems: "center",
  },
  headingTextBig: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "800",
    fontSize: "25px",
    letterSpacing: "0.5px",
    "@media (min-width: 1300px)": {
      fontWeight: "700",
      fontSize: "35px",
    },
  },
  headingTextMedium: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "800",
    fontSize: "15px",
    letterSpacing: "0.5px",
    "@media (min-width: 1300px)": {
      fontWeight: "700",
      fontSize: "20px",
    },
  },
  inputLabel: {
    marginBottom: "5px",
  },

  inputValue: {
    height: "30px",
  },
  inputValueMargin: {
    marginRight: "10px",
  },
  LoginButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  LoginButton: {
    backgroundColor: "#03bfff",
    width: "75px",
    height: "30px",
    borderRadius: "10px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "#03bfff 0px 2px 8px 0px",
    cursor: "pointer",
  },
  LoginButtonDisabled: {
    backgroundColor: "#01678a",
    width: "75px",
    height: "30px",
    borderRadius: "10px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "#03bfff 0px 2px 8px 0px",
    cursor: "not-allowed",
  },
  alreadyAMember: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    color: "#03bfaa",
    textDecoration: "underline",
  },
  loader: {
    height: "100%",
  },
  signUpAsContainer: {
    width: "40%",
    display: "flex",
    justifyContent: "space-between",
  },
  inputLabelPrivacy: {
    cursor: "pointer",
    color: "#03bfaa",
    textDecoration: "underline",
  },
});
export default styles;
