import { combineReducers } from "redux";
import categories from "./categories";
import rfqs from "./rfqs";
import userReducer from "./../users/users";
import paymentPlansReducer from "./../payment-plans/payment-plans";

export default combineReducers({
  categories: categories,
  rfqs: rfqs,
  users: userReducer,
  paymentPlans: paymentPlansReducer,
});
