// import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../resources/styles/views-styles/PricingAndPlanStyles";
// import {
//   MASTER_PERSONAL_PLAN,
//   MASTER_PROFESSIONAL_PLAN,
// } from "../../services/pricingAndPlanService";
import PlanCard from "../helpers/PlanCard";
import configData from "../../config.json";
import { useSelector } from "react-redux";

function PricingAndPlan(props) {
  const { classes } = props;
  const navigate = useNavigate();

  const [plans, setPlans] = useState([]);
  // const [selectedPlan, setSelectedPlan] = useState(plans[0]?.id);
  const user = useSelector((state) => state.auth.user);

  // const handleChangeSelection = (value) => {
  //   setSelectedPlan(value);
  // };

  useEffect(() => {
    axios
      .get(configData.urls.baseURL + configData.urls.getPricingPlan)
      .then((res) => {
        setPlans(res.data);
      })
      .catch((e) => {});
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.headerContent}></div>
      <div className={classes.bodyContent}>
        <div className={classes.title}> Pricing & Plan</div>
        <div className={classes.titleDetails}>
          Simple pricing, No hidden fees, Advanced features for your RFQ
          Management
        </div>

        <div className={classes.plansContainer}>
          {user?.role?.shortCode === "bdr"
            ? plans
                .filter((plan) => plan.type === "bidder")
                .map((plan) => {
                  return <PlanCard plan={plan.info} id={plan.id} />;
                })
            : plans
                .filter((plan) => plan.type === "issuer")
                .map((plan) => {
                  return <PlanCard plan={plan.info} id={plan.id} />;
                })}
          {/* {selectedPlanType === "personal"
            ? MASTER_PERSONAL_PLAN.map((plan) => {
                return (
                  <PlanCard
                    plan={plan}
                    selected={selectedPlan}
                    changeSelection={handleChangeSelection}
                  />
                );
              })
            : MASTER_PROFESSIONAL_PLAN.map((plan) => {
                return (
                  <PlanCard
                    plan={plan}
                    selected={selectedPlan}
                    changeSelection={handleChangeSelection}
                  />
                );
              })} */}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(PricingAndPlan);
