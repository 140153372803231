import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import configData from "./../../config.json";

const baseUrl = configData.urls.baseURL;
const paymentPlans = configData.urls.paymentPlans;

const url = `${baseUrl}${paymentPlans}`;

const slice = createSlice({
  name: "paymentPlans",
  initialState: {
    list: [],
    loading: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    paymentPlansListRequested: (paymentPlans, action) => {
      paymentPlans.loading = true;
      paymentPlans.error = false;
      paymentPlans.errorMessage = "";
    },
    paymentPlansListReceived: (paymentPlans, action) => {
      paymentPlans.list = action.payload;
      paymentPlans.loading = false;
      paymentPlans.error = false;
      paymentPlans.errorMessage = "";
    },
    paymentPlansListRequestFailed: (paymentPlans, action) => {
      paymentPlans.loading = false;
      paymentPlans.error = true;
      paymentPlans.errorMessage = action.payload.message;
    },
  },
});

const {
  paymentPlansListRequested,
  paymentPlansListReceived,
  paymentPlansListRequestFailed,
} = slice.actions;

export const loadPaymentPlans = () =>
  apiCallBegan({
    url,
    onStart: paymentPlansListRequested.type,
    onSuccess: paymentPlansListReceived.type,
    onError: paymentPlansListRequestFailed.type,
  });

export default slice.reducer;
