import { combineReducers } from "redux";

import authReducer from "./auth/auth";
import uiReducer from "./ui/ui";
import entitiesReducer from "./entities/entitiesReducer";
export default combineReducers({
  auth: authReducer,
  ui: uiReducer,
  entities: entitiesReducer,
});
