const styles = (theme) => ({
  container: {
    marginTop: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    marginLeft: "15%",
    borderRadius: "5px",
    boxShadow: "rgba(1, 56, 112, 0.3) 0px 7px 10px 2px",
    padding: "5px",
    margin: "5px",
  },
  title: {
    fontWeight: "700",
    fontSize: "50px",
  },
  active: {
    position: "absolute",
    top: "15%",
    left: "18%",
    borderRadius: "50px",
    backgroundColor: "green",
    color: "white",
    padding: "5px",
  },
  inActive: {
    position: "absolute",
    top: "15%",
    left: "18%",
    borderRadius: "50px",
    backgroundColor: "yellow",
    color: "brown",
    padding: "5px",
  },
  keywords: {
    marginBottom: "50px",
    color: "grey",
    fontStyle: "italic",
  },
  halfContainer: {
    display: "flex",
    width: "90%",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    alignItems: "flex-end",
  },
  onBehalfOf: {
    fontWeight: "600",
    marginBottom: "10px",
  },
  category: {
    color: "#013870",
    fontWeight: "900",
    fontSize: "20px",
    marginBottom: "10px",
  },
  budget: {
    color: "green",
    fontWeight: "900",
    fontSize: "20px",
    marginBottom: "10px",
  },
  responsesDue: {
    color: "red",
    fontWeight: "600",
    fontSize: "17px",
    marginBottom: "10px",
  },
  timeline: {
    color: "#013870",
    fontWeight: "500",
    fontSize: "17px",
    marginBottom: "10px",
  },
  submitAResponse: {
    width: "150px",
    height: "50px",
    borderRadius: "5px",
    backgroundColor: "#013870",
    color: "white",

    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  submitAResponseDisabled: {
    width: "150px",
    height: "50px",
    borderRadius: "5px",
    backgroundColor: "#013870",
    color: "white",

    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "not-allowed",
  },
  download: {
    width: "150px",
    height: "50px",
    borderRadius: "5px",
    backgroundColor: "green",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "10px",
  },
  askQuestion: {
    width: "150px",
    height: "50px",
    borderRadius: "5px",
    backgroundColor: "orange",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "10px",
  },
  askQuestionDisabled: {
    width: "150px",
    height: "50px",
    borderRadius: "5px",
    backgroundColor: "orange",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "not-allowed",
    marginBottom: "10px",
  },
  markAsClosed: {
    width: "150px",
    height: "50px",
    borderRadius: "5px",
    backgroundColor: "brown",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "10px",
  },
  postAnAnswer: {
    width: "180px",
    height: "50px",
    borderRadius: "5px",
    backgroundColor: "darkseagreen",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  summaryTitle: {
    width: "90%",
    margin: "10px 0px 10px 0px",
    fontWeight: "800",
    fontSize: "20px",
  },
  summaryContent: {
    width: "90%",
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  label: {
    marginBottom: "10px",
  },
  input: {
    height: "40px",
  },
  inputArea: {
    height: "80px",
  },
  submit: {
    backgroundColor: "green",
    color: "white",
    cursor: "pointer",
    borderRadius: "5px",
    margin: "0px 10px 0px 10px",
    padding: "10px",
  },
  cancel: {
    backgroundColor: "orange",
    color: "white",
    cursor: "pointer",
    borderRadius: "5px",
    margin: "0px 10px 0px 10px",
    padding: "10px",
  },
  qasContainer: {
    marginLeft: "8%",
    width: "100%",
  },
  qaContainer: {
    margin: "10px",
  },
  question: {
    fontStyle: "Roboto",
    fontWeight: "700",
    fontSize: "15px",
    color: "orange",
    margin: "5px",
  },
  answer: {
    fontStyle: "Roboto",
    fontWeight: "700",
    fontSize: "15px",
    color: "green",
    margin: "5px",
  },
});
export default styles;
