import { Box, Divider, Tab, Tabs } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect } from "react";
import styles from "../../resources/styles/views-styles/ManageRfqStyles";
import CloseRfqsTab from "./common/CloseRfqsTab";
import OpenRfqsTab from "./common/OpenRfqsTab";
import TabPanelCard from "./common/TabPanelCard";
import UnAnsweredRfqsTab from "./common/UnAnsweredRfqsTab";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ManageRfq(props) {
  const { classes } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <Box sx={{ width: "100%", height: "15%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="rfq tabs"
          TabIndicatorProps={{ style: { background: "#01e08f" } }}
        >
          <Tab
            label="Active RFQs"
            {...a11yProps(0)}
            className={classes.tabOption}
          />
          <Tab
            label="Finished RFQs"
            {...a11yProps(1)}
            className={classes.tabOption}
          />
          <Tab
            label="Open Questions"
            {...a11yProps(2)}
            className={classes.tabOption}
          />
        </Tabs>
        <Divider />
      </Box>
      <Box sx={{ width: "100%", height: "85%" }}>
        <TabPanelCard value={value} index={0}>
          <OpenRfqsTab />
        </TabPanelCard>
        <TabPanelCard value={value} index={1}>
          <CloseRfqsTab />
        </TabPanelCard>
        <TabPanelCard value={value} index={2}>
          <UnAnsweredRfqsTab />
        </TabPanelCard>
      </Box>
    </div>
  );
}

export default withStyles(styles)(ManageRfq);
