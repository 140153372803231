import { ClassNames } from "@emotion/react";
import { withStyles } from "@mui/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../resources/styles/common-styles/Navbar";
import { checkBidder, checkIssuer } from "../../../services/checkRole";
import { navbarSelectionChanged } from "../../../store/ui/navBar";
import { useNavigate } from "react-router-dom";
import rfqBaseIcon from "../../../resources/design-icons/rfqbase.png";

function Navbar(props) {
  const { classes } = props;
  const user = useSelector((state) => state.auth.user);
  const auth = useSelector((state) => state.auth);
  const navBar = useSelector((state) => state.ui.navBar);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.navIcon}>
        <img
          style={{ height: "180px" }}
          src={rfqBaseIcon}
          alt={"RFQ Base"}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      {user?.role?.shortCode === "adm" ? (
        <div className={classes.rightContent}>
          <div className={classes.buttonsContainer}>
            {auth.loggedIn ? (
              <div
                className={
                  navBar.selection === "marketplace"
                    ? classes.navLinksActive
                    : classes.navLinks
                }
                onClick={() => {
                  dispatch(navbarSelectionChanged("marketplace"));
                  navigate("/marketplace");
                }}
              >
                Marketplace
              </div>
            ) : (
              <></>
            )}
             {auth.loggedIn ? (
              <div
                className={
                  navBar.selection === "users"
                    ? classes.navLinksActive
                    : classes.navLinks
                }
                onClick={() => {
                  dispatch(navbarSelectionChanged("users"));
                  navigate("/admin");
                }}
              >
                Users
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className={classes.rightContent}>
          <div className={classes.buttonsContainer}>
            {auth.loggedIn ? (
              <div
                className={
                  navBar.selection === "marketplace"
                    ? classes.navLinksActive
                    : classes.navLinks
                }
                onClick={() => {
                  dispatch(navbarSelectionChanged("marketplace"));
                  navigate("/marketplace");
                }}
              >
                Marketplace
              </div>
            ) : (
              <></>
            )}

            {auth.loggedIn ? (
              <div
                className={
                  navBar.selection === "dashboard"
                    ? classes.navLinksActive
                    : classes.navLinks
                }
                onClick={() => {
                  dispatch(navbarSelectionChanged("dashboard"));
                  navigate("/dashboard");
                }}
              >
                Dashboard
              </div>
            ) : (
              <></>
            )}
          </div>
          {checkIssuer(user?.role?.shortCode) && auth.loggedIn ? (
            <div
              className={classes.postAProposalContainer}
              onClick={(e) => {
                if (user?.role?.shortCode === "issX") navigate("/addRFQ");
                else navigate("/pricing-and-plan");
              }}
            >
              <div className={classes.postAProposalBtn}>Post a Quotation</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {auth.loggedIn ? (
        <a
          className={classes.logOut}
          href="/"
          onClick={() => {
            localStorage.clear("token");
            localStorage.clear("user");

            localStorage.clear("loggedIn");
          }}
        >
          Logout
        </a>
      ) : (
        <a
          className={classes.signIn}
          href="/signin"
          onClick={() => {
            localStorage.clear("token");
            localStorage.clear("user");

            localStorage.clear("loggedIn");
          }}
        >
          SignIn
        </a>
      )}
    </div>
  );
}
export default withStyles(styles)(Navbar);
