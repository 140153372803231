const drawerWidth = 250;

const row = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const styles = (theme) => ({
  root: {
    marginTop: "60px",
  },
  drawer: {
    "&.MuiDrawer-paper": {},
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    borderRadius: "5px",
  },
  drawerPaper: {
    "&.MuiDrawer-paper": {
      width: drawerWidth,
      backgroundColor: "#013870",
      color: "white",
    },
  },
  drawerOption: {
    "&.MuiListItem-root": {
      "&:hover": {
        cursor: "pointer",
        color: "#01e08f",
      },
      width: "100%",
    },
  },
  childContainer: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    minHeight: "85vh",
    zIndex: "1400",
  },
  footer: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#013870",
    // zIndex: "1204",
  },
  footerTopRow: {
    ...row,
    justifyContent: "flex-start",
  },
  footerLogoContainer: {
    // width: "500px",
    height: "140px",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  footerLinksContainer: {
    ...row,
    flexGrow: 1,
    color: "white",
    "& p": {
      margin: "auto 20px",
      "&:hover": {
        cursor: "pointer",
        color: "#01e08f",
      },
    },
  },
  footerSecondRow: {
    ...row,
    marginLeft: "10px",
  },
  socialLinks: {
    ...row,
    justifyContent: "flex-start",
    "& div": {
      margin: "auto 10px",
      color: "white",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  footerThirdRow: {
    ...row,
    color: "#6c757d",
    marginTop: "20px",
    // marginBottom: "20px",
    paddingBottom: "1%",
  },
  termsAndPolicy: {
    ...row,
    "& p": {
      margin: "auto 20px",
      "&:hover": {
        cursor: "pointer",
        color: "#01e08f",
      },
    },
  },
  copyright: {
    margin: "auto 20px",
    fontSize: "small",
  },
});

export default styles;
