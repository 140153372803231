import { withStyles } from "@mui/styles";
import React from "react";
import styles from "../../resources/styles/helpers-styles/IssuerProfileFormStyles";

function IssuerProfileForm(props) {
  const { classes } = props;
  const {
    about,
    setAbout,
    companyName,
    setCompanyName,
    website,
    setWebsite,
    organizationType,
    setOrganizationType,
    industry,
    setIndustry,
    mission,
    setMission,
    organizationSize,
    setOrganizationSize,
    additionalInformation,
    setAdditionalInformation,
  } = props.values;
  const { handleSubmit } = props;
  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <div className={classes.formControl}>
          <label className={classes.formLabel}>About</label>
          <textarea
            type={"text"}
            className={classes.formInput}
            rows="6"
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
        </div>

        {/*first row container*/}
        <div className={classes.rowInputsContainer}>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>
                Organization/Company Name
              </label>
              <input
                type={"text"}
                className={classes.formInput}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Website</label>
              <input
                type={"text"}
                className={classes.formInput}
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/**Second Row COntainer */}
        <div className={classes.rowInputsContainer}>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Organization Type</label>
              <select
                className={classes.formSelect}
                value={organizationType}
                onChange={(e) => setOrganizationType(e.target.value)}
              >
                <option value={""}>Select an organization type</option>
                <option value={"public company"}>Public Company</option>
                <option value={"private company"}>Private Company</option>
                <option value={"government entity"}>Government Entity</option>
                <option value={"501(c)3 non profit"}>501(C)3 Nonprofit</option>
                <option value={"501(c)4 non profit"}>501(C)4 Nonprofit</option>
                <option value={"political entity"}>Political Entity</option>
                <option value={"coalition"}>Coalition</option>
                <option value={"foundation"}>Foundation</option>
                <option value={"think tank"}>Think Tank</option>
                <option value={"other"}>Other</option>
              </select>
            </div>
          </div>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Industry</label>
              <select
                className={classes.formSelect}
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
              >
                <option value={""}>Select Industry</option>
                <option value={"animals & wildlife"}>Animals & Wildlife</option>
                <option value={"arts, culture & humanities"}>
                  Arts, Culture & Humanities
                </option>
                <option value={"civic"}>Civic</option>
                <option value={"criminal justice"}>Criminal Justice</option>
                <option value={"democracy"}>Democracy</option>
                <option value={"disability rights"}>Disability Rights</option>
                <option value={"economic prosperity"}>
                  Economic Prosperity
                </option>
                <option value={"environment & energy"}>
                  Environment & Energy
                </option>
                <option value={"education"}>Education</option>
                <option value={"faith & religion"}>Faith & Religion</option>
                <option value={"international affairs"}>
                  International Affairs
                </option>
                <option value={"healthcare"}>Healthcare</option>
                <option value={"human services"}>Human Services</option>
                <option value={"humanitarian relief"}>
                  Humanitarian Relief
                </option>
                <option value={"immigration"}>Immigration</option>
                <option value={"labor & unions"}>Labor & Unions</option>
                <option value={"lgbtqia+"}>LGBTQIA+</option>
                <option value={"news & media+"}>News & Media+</option>
                <option value={"philanthropy"}>Philanthropy</option>
                <option value={"policy"}>Policy</option>
                <option value={"privacy"}>Privacy</option>
                <option value={"racial justice"}>Racial Justice</option>
                <option value={"recreation"}>Recreation</option>
                <option value={"social services"}>Social Services</option>
                <option value={"technology"}>Technology</option>
                <option value={"transportation"}>Transportation</option>
                <option value={"veterans"}>Veterans</option>
                <option value={"other"}>Other</option>
              </select>
            </div>
          </div>
        </div>

        <div className={classes.formControl}>
          <label className={classes.formLabel}>Mission</label>
          <textarea
            type={"text"}
            className={classes.formInput}
            rows="10"
            value={mission}
            onChange={(e) => setMission(e.target.value)}
          />
        </div>

        <div className={classes.formControl}>
          <label className={classes.formLabel}>Organization Size</label>
          <select
            className={classes.formSelect}
            value={organizationSize}
            onChange={(e) => setOrganizationSize(e.target.value)}
          >
            <option value={""}>Select organization size</option>
            <option value={"1-5"}>1 - 5 Employees</option>
            <option value={"5-10"}>5 - 10 Employees</option>
            <option value={"10-30"}>10 - 30 Employees</option>
            <option value={"30-50"}>30 - 50 Employees</option>
            <option value={"50-100"}>50 - 100 Employees</option>
            <option value={"100+"}>100+ Employees</option>
          </select>
        </div>

        <div className={classes.formControl}>
          <label className={classes.formLabel}>Additional Information</label>
          <textarea
            type={"text"}
            className={classes.formInput}
            rows="6"
            value={additionalInformation}
            onChange={(e) => setAdditionalInformation(e.target.value)}
          />
        </div>

        <div className={classes.formControl}>
          <button type={"submit"} className={classes.submit}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default withStyles(styles)(IssuerProfileForm);
