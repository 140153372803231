import styles from "../resources/styles/views-styles/marketplace";
import { withStyles } from "@mui/styles";
import { ClassNames } from "@emotion/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../store/entities/categories";
import RFQMarketplaceTile from "./views/common/RFQMarketplaceTile";
import { getRFQs } from "../store/entities/rfqs";
import { authenticateAlreadySignedInUser } from "../store/auth/auth";
function Marketplace(props) {
  const { classes } = props;
  const dispatch = useDispatch();

  const [selectedCategories, setSelectedCategories] = useState("All");

  const categories = useSelector(
    (state) => state.entities.categories.categories
  );
  const rfqs = useSelector((state) => state.entities.rfqs.rfqs);

  const checkCategorySelected = (category) => {
    if (selectedCategories === category) return true;
    else return false;
  };

  const checkFilterRFQs = (rfq) => {
    if (selectedCategories === "All") return true;
    else if (selectedCategories.includes(rfq)) return true;
  };

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getRFQs());
    dispatch(authenticateAlreadySignedInUser(localStorage.getItem("token")));
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.categoriesContainer}>
        {categories?.map((category) => (
          <div
            key={category.categoryShortCode}
            onClick={() => {
              setSelectedCategories(category.category);
            }}
            className={
              checkCategorySelected(category.category)
                ? classes.categoryActive
                : classes.category
            }
          >
            {category.category}
          </div>
        ))}{" "}
      </div>
      <div className={classes.rfqContainer}>
        {rfqs.length > 0 ? (
          rfqs?.map((rfq) => {
            return checkFilterRFQs(rfq.category) ? (
              <RFQMarketplaceTile
                id={rfq.id}
                title={rfq.title}
                summary={rfq.summary}
                organization={rfq.organization}
                category={rfq.category}
                submissionDeadline={rfq.submissionDeadline}
                approximateStartDate={rfq.approximateStartDate}
                approximateEndDate={rfq.approximateEndDate}
                estimatedHoursPerWeek={rfq.estimatedHoursPerWeek}
                budget={rfq.budget}
                keywords={rfq.keywords}
                bidCount={rfq.bids.length}
                status={rfq.status}
              />
            ) : (
              <></>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
export default withStyles(styles)(Marketplace);
