import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Paper,
  Radio,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import styles from "../../resources/styles/views-styles/AdminPageStyles";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  loadUsers,
  updateUserPlan,
  updateUserStatus,
} from "../../store/users/users";
import CloseIcon from "@mui/icons-material/Close";
import { navbarSelectionChanged } from "../../store/ui/navBar";
import { loadPaymentPlans } from "../../store/payment-plans/payment-plans";

function AdminPage(props) {
  const { classes } = props;

  const [userToUpdate, setUserToUpdate] = useState("");
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [userStatus, setUserStatus] = useState(false);
  const [userServicePackage, setUserServicePackage] = useState("");

  const dispatch = useDispatch();
  const users = useSelector((state) => state.entities.users.list);
  const usersLoading = useSelector((state) => state.entities.users.loading);
  const usersError = useSelector((state) => state.entities.users.error);
  const usersErrorMessage = useSelector(
    (state) => state.entities.users.errorMessage
  );
  const paymentPlans = useSelector((state) => state.entities.paymentPlans.list);

  useEffect(() => {
    dispatch(navbarSelectionChanged("users"));
    if (users.length === 0) dispatch(loadUsers());
    if (paymentPlans.length === 0) dispatch(loadPaymentPlans());
  }, []);

  const updateUser = () => {
    const status = userStatus ? 1 : 2;
    dispatch(updateUserStatus(userToUpdate, status));
    if (userServicePackage && userServicePackage !== "")
      dispatch(updateUserPlan(userToUpdate, userServicePackage));
  };

  const getUserServicePackage = (roleShortCode) => {
    const servicePackage = paymentPlans.find(
      (plan) => plan.roleShortCode === roleShortCode
    );
    if (servicePackage) return servicePackage.id;
    else return "";
  };

  const renderUsers = () => {
    if (usersError)
      return (
        <TableRow component={Paper} className={classes.dataRow}>
          <TableCell colSpan={5}>{usersErrorMessage?.message}</TableCell>
        </TableRow>
      );
    if (users.length === 0)
      return (
        <TableRow component={Paper} className={classes.dataRow}>
          <TableCell colSpan={5}>No Records to Show</TableCell>
        </TableRow>
      );
    const list = users.map((user, idx) => (
      <TableRow key={user.id} component={Paper} className={classes.dataRow}>
        <TableCell>{`.${idx + 1}`}</TableCell>
        <TableCell>{user?.profile?.firstName || "N/A"}</TableCell>
        <TableCell>{user.role?.name}</TableCell>
        <TableCell>{user.status === 1 ? "Active" : "Blocked"}</TableCell>
        {/* <TableCell>{user?.profile?.servicePackage || "N/A"}</TableCell> */}
        <TableCell>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                setUserToUpdate(user.id);
                setUserStatus(user.status === 1);
                setUserServicePackage(
                  getUserServicePackage(user.role?.shortCode)
                );
                setOpenUpdateDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));
    return list;
  };

  const renderServicePackageOptions = () => {
    const list = paymentPlans.map((plan) => (
      <option key={plan.id} value={plan.id}>
        {plan.name}
      </option>
    ));
    return list;
  };
  return (
    <div className={classes.root}>
      <div className={classes.usersTableContainer}>
        <TableContainer className={classes.tableContainer}>
          <Backdrop className={classes.Backdrop} open={usersLoading}>
            <CircularProgress />
          </Backdrop>

          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeading}>
                <TableCell>No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>User Plan Type</TableCell>
                <TableCell>Status</TableCell>
                {/* <TableCell>Service Package</TableCell> */}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>{renderUsers()}</TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog disableBackdropClick disableEscapeKeyDown open={openUpdateDialog}>
        <DialogTitle>
          <div className={classes.dialogTitle}>
            <Typography className={classes.dialogTitleText}>
              Edit user
            </Typography>
            <IconButton
              onClick={() => {
                setOpenUpdateDialog(false);
                setUserToUpdate(null);
              }}
              className={classes.dialogCloseIcon}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {usersError ? <Paper>{usersErrorMessage?.message}</Paper> : ""}
          <InputLabel>User Status</InputLabel>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={userStatus}
                  onChange={() => setUserStatus(!userStatus)}
                />
              }
              label={userStatus ? "Active" : "Blocked"}
            />
          </FormControl>
          <InputLabel>User Service Package:</InputLabel>
          <FormControl fullWidth>
            <Select
              value={userServicePackage}
              fullWidth
              native
              onChange={(e) => setUserServicePackage(e.target.value)}
            >
              {renderServicePackageOptions()}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenUpdateDialog(false);
              setUserToUpdate(null);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateUser();
              setOpenUpdateDialog(false);
              setUserToUpdate(null);
            }}
            className={classes.formSaveBtn}
          >
            Update user
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(AdminPage);
