const row = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  
  const formInput = {
    border: `1px solid #7F92A3`,
    borderRadius: "4px",
    padding: "10px 5px",
    width: "100%",
    boxSizing: "border-box",
  };
  
  const styles = (theme) => ({
    root: {
      margin: "20px",
      marginTop: "60px",
    },
    formControl: {
      ...row,
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      "& *": {
        marginTop: "5px",
        marginBottom: "5px",
      },
    },
    formControlRow: {
      ...row,
    },
    formLabel: {
      fontWeight: "bold",
      // fontSize: "1.30vw"
    },
    formSelect: {
      ...formInput,
      padding: "10px 5px",
    },
    formInput: {
      ...formInput,
    },
    formFileInput: {
      ...formInput,
      ...row,
      "&:hover": {
        cursor: "pointer",
      },
    },
    rowInputsContainer: {
      ...row,
      flexWrap: "wrap",
    },
    rowInput: {
      width: "49%",
      margin: "auto 0.5%",
    },
    keywordsContainer: {
      ...row,
      justifyContent: "flex-start",
      minHeight: "25px",
      maxHeight: "10px",
      width: "100%",
    },
    keyword: {
      ...row,
      margin: "auto 5px",
      borderRadius: "4px",
      backgroundColor: "#BFBFBF",
      padding: "2px 4px",
      "& *": {
        margin: "auto 2px",
      },
    },
    submit: {
      alignSelf: "flex-end",
      border: "1px solid #01e08f",
      borderRadius: "4px",
      backgroundColor: "#01e08f",
      padding: "5px 10px",
      color: "white",
      fontWeight: "bold",
      fontSize: "large",
      textTransform: "capitalize",
      transition: "all ease-in 0.5s",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "white",
        color: "#01e08f",
      },
    },
  });
  export default styles;
  