import "./App.css";

import { ThemeProvider } from "@mui/styles";

import theme from "./resources/themes/themes";

import { Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import SignIn from "./components/views/SignIn";
import Signup from "./components/views/Signup";
import PricingAndPlan from "./components/views/PricingAndPlan";
import Marketplace from "./components/Marketplace";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./components/views/common/Navbar";
import AddRFQ from "./components/views/common/AddRFQ";
import ViewRFQ from "./components/views/common/ViewRFQ";

import {
  authenticateAlreadySignedInUser,
  authenticateUser,
  setAuthUser,
} from "./store/auth/auth";
import Dashboard from "./components/views/common/Dashboard";
import ManageRfq from "./components/views/ManageRfq";
import AdminPage from "./components/views/AdminPage";
import landingPage from "./components/views/website/WebsiteLandingPage";
import WebsiteLandingPage from "./components/views/website/WebsiteLandingPage";
import ProfileSetting from "./components/views/ProfileSetting";
import Settings from "./components/views/common/Settings";
function App() {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log("refresh");
    dispatch(authenticateAlreadySignedInUser(localStorage.getItem("token")));
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Navbar />

        <Routes>
          <Route path="/" element={<WebsiteLandingPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<Signup />} />{" "}
          <Route path="/pricing-and-plan" element={<PricingAndPlan />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/addRFQ" element={<AddRFQ />} />
          <Route path="/viewRFQ/:id" element={<ViewRFQ />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="rfq" element={<ManageRfq />} />
            <Route path="profile" element={<ProfileSetting />} />
            <Route path="change-password" element={<Settings />} />
          </Route>
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
