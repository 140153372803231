import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Toolbar,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import styles from "../../../resources/styles/common-styles/DashboardStyles";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import rfqBaseIcon from "../../../resources/design-icons/rfqbase.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import TabPanelCard from "./TabPanelCard";
import AccountBoxIcon from '@mui/icons-material/AccountBox';

function Dashboard(props) {
  const { classes } = props;

  const locations = useLocation();
  const navigate = useNavigate();

  const handleDrawerOptionChange = (value) => () => {
    navigate(`/${locations.pathname.split("/")[1]}/${value}`);
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <Toolbar />
        <List>
          <ListItem
            className={classes.drawerOption}
            onClick={handleDrawerOptionChange("rfq")}
          >
            <ListItemIcon>
              <LibraryBooksIcon />
            </ListItemIcon>
            <ListItemText primary={"Manage RFQs"} />
          </ListItem>
          <ListItem
            className={classes.drawerOption}
            onClick={handleDrawerOptionChange("profile")}
          >
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary={"Edit Profile"} />
          </ListItem>
          <ListItem
            className={classes.drawerOption}
            onClick={handleDrawerOptionChange("change-password")}
          >
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary={"Change Password"} />
          </ListItem>
        </List>
      </Drawer>
      <div className={classes.childContainer}>
        <Outlet />
      </div>
      {/* <div className={classes.footer}>
        <div className={classes.footerTopRow}>
          <div className={classes.footerLogoContainer}>
            <img src={rfqBaseIcon} alt="RFQ Base" />
          </div>
          <div className={classes.footerLinksContainer}>
            <p>About Us</p>
            <p>Blog</p>
            <p>What's An RFQ</p>
            <p>Feedback</p>
            <p>FAQs</p>
            <p>Contact</p>
          </div>
        </div>
        <div className={classes.footerSecondRow}>
          <div className={classes.socialLinks}>
            <div className={classes.socialIconContainer}>
              <FacebookIcon />
            </div>
            <div className={classes.socialIconContainer}>
              <TwitterIcon />
            </div>
            <div className={classes.socialIconContainer}>
              <InstagramIcon />
            </div>
            <div className={classes.socialIconContainer}>
              <LinkedInIcon />
            </div>
          </div>
        </div>
        <div className={classes.footerThirdRow}>
          <div className={classes.termsAndPolicy}>
            <p>Privacy Policy</p>
            <p>Terms of Service</p>
          </div>
          <p className={classes.copyright}>
            &copy; {new Date().getFullYear()} RFQbase. All rights reserved.
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default withStyles(styles)(Dashboard);
