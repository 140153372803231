import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import configData from "./../../config.json";

const baseUrl = configData.urls.baseURL;
const users = configData.urls.users;
const changeStatus = configData.urls.userStatus;
const changePlan = configData.urls.userPlanChange;

const url = `${baseUrl}${users}`;
const statusUrl = `${baseUrl}${changeStatus}`;
const planUrl = `${baseUrl}${changePlan}`;

const slice = createSlice({
  name: "users",
  initialState: {
    list: [],
    loading: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    usersListRequested: (users, action) => {
      users.loading = true;
      users.error = false;
      users.errorMessage = "";
    },
    usersListReceived: (users, action) => {
      users.list = action.payload;
      users.loading = false;
      users.error = false;
      users.errorMessage = "";
    },
    usersListRequestFailed: (users, action) => {
      users.loading = false;
      users.error = true;
      users.errorMessage = action.payload.message;
    },
    userUpdateRequested: (users, action) => {
      users.loading = true;
      users.error = false;
      users.errorMessage = "";
    },
    userUpdated: (users, action) => {
      const userIdx = users.list.findIndex(
        (user) => user.id === action.payload.id
      );
      users.list[userIdx] = { ...users.list[userIdx], ...action.payload };
      users.loading = false;
      users.error = false;
      users.errorMessage = "";
    },
    userUpdateFailed: (users, action) => {
      users.loading = false;
      users.error = false;
      users.errorMessage = "";
    },
  },
});

const {
  usersListRequested,
  usersListReceived,
  usersListRequestFailed,
  userUpdateRequested,
  userUpdated,
  userUpdateFailed,
} = slice.actions;

export const loadUsers = () =>
  apiCallBegan({
    url,
    onStart: usersListRequested.type,
    onSuccess: usersListReceived.type,
    onError: usersListRequestFailed.type,
  });

export const updateUserStatus = (userId, status) =>
  apiCallBegan({
    url: `${statusUrl}/${userId}`,
    method: "patch",
    data: { status },
    onStart: userUpdateRequested.type,
    onSuccess: userUpdated.type,
    onError: userUpdateFailed.type,
  });

export const updateUserPlan = (userId, paymentID) =>
  apiCallBegan({
    url: `${planUrl}/${userId}`,
    method: "patch",
    data: { paymentID },
    onStart: userUpdateRequested.type,
    onSuccess: userUpdated.type,
    onError: userUpdateFailed.type,
  });

export default slice.reducer;
