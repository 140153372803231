import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import configData from "../../config.json";

const baseUrl = configData.urls.baseURL;
const rfqUrl = configData.urls.rfqUrl;
const openRfqUrl = configData.urls.openRfqUrl;
const closedRfqUrl = configData.urls.closedRfqUrl;
const unAnsweredRfqUrl = configData.urls.unAnsweredRfqUrl;
const rfqBidUrl = configData.urls.rfqBidUrl;
const askQuestionUrl = configData.urls.askAQuestionUrl;

const slice = createSlice({
  name: "rfqs",
  initialState: {
    rfqs: [],
    openRfqs: [],
    closedRfqs: [],
    unAnsweredRfqs: [],
    isRefreshRFQs: false,
    loading: false,
    error: false,
    errorMessage: false,
  },
  reducers: {
    getRFQSuccess: (state, action) => {
      state.rfqs = action.payload;
      state.isRefreshRFQs = false;
    },
    postBidRFQSuccess: (state, action) => {
      state.isRefreshRFQs = true;
    },
    openRfqsRequested: (rfqs, action) => {
      rfqs.loading = true;
      rfqs.error = false;
      rfqs.errorMessage = "";
    },
    openRfqsReceived: (rfqs, action) => {
      rfqs.openRfqs = action.payload;
      rfqs.loading = false;
      rfqs.error = false;
      rfqs.errorMessage = "";
    },
    openRfqsRequestFailed: (rfqs, action) => {
      rfqs.loading = false;
      rfqs.error = true;
      rfqs.errorMessage = action.payload.message;
    },
    closedRfqsRequested: (rfqs, action) => {
      rfqs.loading = true;
      rfqs.error = false;
      rfqs.errorMessage = "";
    },
    closedRfqsReceived: (rfqs, action) => {
      rfqs.closedRfqs = action.payload;
      rfqs.loading = false;
      rfqs.error = false;
      rfqs.errorMessage = "";
    },
    closedRfqsRequestFailed: (rfqs, action) => {
      rfqs.loading = false;
      rfqs.error = true;
      rfqs.errorMessage = action.payload.message;
    },
    unAnsweredRfqsRequested: (rfqs, action) => {
      rfqs.loading = true;
      rfqs.error = false;
      rfqs.errorMessage = "";
    },
    unAnsweredRfqsReceived: (rfqs, action) => {
      rfqs.unAnsweredRfqs = action.payload;
      rfqs.loading = false;
      rfqs.error = false;
      rfqs.errorMessage = "";
    },
    unAnsweredRfqsRequestFailed: (rfqs, action) => {
      rfqs.loading = false;
      rfqs.error = true;
      rfqs.errorMessage = action.payload.message;
    },
  },
});

export const {
  getRFQSuccess,
  postBidRFQSuccess,
  openRfqsRequested,
  openRfqsReceived,
  openRfqsRequestFailed,
  closedRfqsRequested,
  closedRfqsReceived,
  closedRfqsRequestFailed,
  unAnsweredRfqsRequested,
  unAnsweredRfqsReceived,
  unAnsweredRfqsRequestFailed,
} = slice.actions;

export const getRFQs = () =>
  apiCallBegan({
    url: baseUrl + rfqUrl,
    method: "get",
    onSuccess: getRFQSuccess.type,
  });

export const getOpenRFQs = () =>
  apiCallBegan({
    url: baseUrl + openRfqUrl,
    method: "get",
    onStart: openRfqsRequested.type,
    onSuccess: openRfqsReceived.type,
    onError: openRfqsRequestFailed.type,
  });

export const getClosedRFQs = () =>
  apiCallBegan({
    url: baseUrl + closedRfqUrl,
    method: "get",
    onStart: closedRfqsRequested.type,
    onSuccess: closedRfqsReceived.type,
    onError: closedRfqsRequestFailed.type,
  });

export const getUnAnsweredRFQs = () =>
  apiCallBegan({
    url: baseUrl + unAnsweredRfqUrl,
    method: "get",
    onStart: unAnsweredRfqsRequested.type,
    onSuccess: unAnsweredRfqsReceived.type,
    onError: unAnsweredRfqsRequestFailed.type,
  });

export const postRFQBid = (rfq, bidPrice) =>
  apiCallBegan({
    url: baseUrl + rfqUrl + rfqBidUrl,
    data: { rfq, bidPrice },
    method: "post",
    onSuccess: postBidRFQSuccess.type,
  });

export const askQuestion = (data) =>
  apiCallBegan({
    url: baseUrl + rfqUrl + askQuestionUrl,
    data,
    method: "post",
    onSuccess: postBidRFQSuccess.type,
  });

export const postAnAnswer = (id, data) =>
  apiCallBegan({
    url: baseUrl + rfqUrl + askQuestionUrl + `/${id}`,
    data,
    method: "patch",
    onSuccess: postBidRFQSuccess.type,
  });

export const patchRFQs = (id, data) =>
  apiCallBegan({
    url: baseUrl + rfqUrl + `/${id}`,
    data,
    method: "patch",
    onSuccess: postBidRFQSuccess.type,
  });

export default slice.reducer;
