const styles = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      borderRadius: "4px",
      // backgroundColor:"#ECEFF4"
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#707070", //'rgba(0,0,0,.1)',
      outline: "1px solid #D7E0E7",
      borderRadius: "4px",
    },
  },

  root: {
    margin: "20px",
    marginTop: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: `calc(100vh - 80px)`,
  },
  usersTableContainer: {
    width: "75%",
    maxWidth: "1280px",
    height: "100%",
  },
  tableContainer: {
    width: "95% !important",
    margin: "2%",
    padding: "1em",
    height: "90%",
    position: "relative",
    zIndex: 0,
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: `0 0.4em`,
  },
  tableHeading: {
    "& th": {
      borderBottom: "none",
      color: "#C2C5C7",
      fontWeight: "bold",
    },
  },
  tableBody: {
    height: "98%",
  },
  dataRow: {
    padding: "1em",
    borderRadius: "8px",
    backgroundColor: theme.palette.common.white,
    margin: "5px",
    "& td": {
      borderBottom: "none",
    },
    // "&:nth-of-type(odd)": {
    //   background: `linear-gradient(to right,rgba(157,157,157,0.025),rgba(70,70,70,0.025))`,
    // },
    // "&:nth-of-type(even)": {
    //   backgroundColor: "white",
    // },

    "&:hover": {
      cursor: "pointer",
      boxShadow: `0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)`,
      backgroundColor: theme.palette.common.white,
    },
  },
  Backdrop: {
    "&.MuiBackdrop-root": {
      position: "absolute",
      zIndex: 3,
      borderRadius: "4px",
      backgroundColor: "transparent",
    },
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogTitleText: {
    marginRight: "auto",
    float: "left",
    fontWeight: "bold",
  },
  dialogCloseIcon: {
    borderRadius: "4px",
    backgroundColor: "cyan",
    "&:hover": {
      backgroundColor: "pink",
    },
  },
});

export default styles;
