import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import configData from "../../config.json";

const baseUrl = configData.urls.baseURL;
const getCategoriesUrl = configData.urls.categories;

const slice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
  },
  reducers: {
    getCategoriesSuccess: (state, action) => {
      state.categories = [];
      state.categories = [{ category: "All", categoryShortCode: "all" }];
      state.categories = [...state.categories, ...action.payload];
      state.categories = [
        ...state.categories,
        { category: "Others", categoryShortCode: "others" },
      ];
    },
  },
});

export const { getCategoriesSuccess } = slice.actions;

export const getCategories = () =>
  apiCallBegan({
    url: baseUrl + getCategoriesUrl,
    method: "get",

    onSuccess: getCategoriesSuccess.type,
  });

export default slice.reducer;
