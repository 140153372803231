import { withStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import styles from "../../resources/styles/helpers-styles/IssuerProfileFormStyles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Divider } from "@mui/material";
import {
  Cancel,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { expertiseOptions, languagesOptions } from "../../services/constants";

function BidderProfileForm(props) {
  const { classes } = props;
  const { handleSubmit, file } = props;
  const {
    summary,
    setSummary,
    firstName,
    setFirstName,
    companyName,
    setCompanyName,
    website,
    setWebsite,
    expertise,
    setExpertise,
    skills,
    setSkills,
    minimumBudget,
    setMinimumBudget,
    hourlyRate,
    setHourlyRate,
    teamSize,
    setTeamSize,
    languages,
    setLanguages,
    certifications,
    setCertifications,
  } = props.values;

  const [openExpertiseSelect, setOpenExpertiseSelect] = useState(false);
  const [openLanguagesSelect, setOpenLanguagesSelect] = useState(false);

  const [certificate, setCertificate] = useState("");
  const [certificateOrganization, setCertificateOrganization] = useState("");
  const [skill, setSkill] = useState("");

  const fileRef = useRef(null);
  const expertiseSelectRef = useRef(null);
  const languagesSelectRef = useRef(null);

  const renderExpertiseOptions = () => {
    const list = expertiseOptions.map((opt, idx) => (
      <option
        value={opt.value}
        key={idx}
        className={classes.selectOption}
        onClick={(e) => {
          setExpertise(expertise + "," + e.target.value);
          setOpenExpertiseSelect(!openExpertiseSelect);
        }}
      >
        {opt.text}
      </option>
    ));
    return list;
  };

  const getExpertiseText = (val) => {
    const opt = expertiseOptions.find((expt) => expt.value === val);
    return opt?.text;
  };

  const removeExpertise = (index) => () => {
    const arrCopy = [...expertise.split(",")];
    arrCopy.splice(index, 1);
    let newExpertise = "";
    arrCopy.forEach((val) => {
      if (val !== "") newExpertise = newExpertise + "," + val;
    });
    setExpertise(newExpertise);
    console.log("expertise", expertise);
  };

  const renderExpertise = () => {
    if (expertise === "") return "Select...";
    const arr = expertise.split(",");
    const list = arr.map((value, index) => {
      if (value !== "") {
        return (
          <span key={index} className={classes.keyword}>
            <p>{getExpertiseText(value)}</p>

            <Cancel
              className={classes.cancelSelection}
              onClick={removeExpertise(index)}
            />
          </span>
        );
      }
    });
    return list;
  };

  /**Skills related methods */

  const handleSkillKeyUp = (e) => {
    const keywordsArray = skills?.split(",");
    if (keywordsArray?.length >= 5) return;
    if (e.key == "Enter" || e.keyCode === 13) {
      const trimmedValue = e.target.value.trim().replaceAll(" ", "-");
      if (skills === "") setSkills(trimmedValue);
      else setSkills(skills + "," + trimmedValue);
      setSkill("");
    }
  };

  const removeSkill = (idx) => () => {
    const keywordsArray = skills?.split(",");
    let resultArr = [...keywordsArray];
    resultArr.splice(idx, 1);
    let stringArr = resultArr[0];
    for (let i = 1; i < resultArr.length; i++) {
      stringArr += "," + resultArr[i];
    }
    setSkills(stringArr);
  };

  const renderSkills = () => {
    let keywordsArray = skills?.split(",");
    if (
      keywordsArray?.length &&
      (keywordsArray[0] === "" || keywordsArray[0] == " ")
    )
      keywordsArray.splice(0, 1);
    const list = keywordsArray?.map((word, idx) => (
      <div className={classes.keyword} key={idx}>
        <p>{word}</p>
        <Cancel onClick={removeSkill(idx)} />
      </div>
    ));
    return list;
  };

  /**Languages field related methods */
  const renderLanguagesOptions = () => {
    const list = languagesOptions.map((opt, idx) => (
      <option
        value={opt.value}
        key={idx}
        className={classes.selectOption}
        onClick={(e) => {
          setLanguages(languages + "," + e.target.value);
          setOpenLanguagesSelect(!openLanguagesSelect);
        }}
      >
        {opt.text}
      </option>
    ));
    return list;
  };

  const getLanguageText = (val) => {
    const opt = languagesOptions.find((lang) => lang.value === val);
    return opt?.text;
  };

  const removeLanguage = (index) => () => {
    const arrCopy = [...languages.split(",")];
    arrCopy.splice(index, 1);
    let newLanguages = "";
    arrCopy.forEach((val) => {
      if (val !== "") newLanguages = newLanguages + "," + val;
    });
    setLanguages(newLanguages);
  };

  const renderLanguages = () => {
    if (languages === "") return "Select...";
    const arr = languages.split(",");
    const list = arr.map((value, index) => {
      if (value !== "") {
        return (
          <span key={index} className={classes.keyword}>
            <p>{getLanguageText(value)}</p>

            <Cancel
              className={classes.cancelSelection}
              onClick={removeLanguage(index)}
            />
          </span>
        );
      }
    });
    return list;
  };

  /**Certifications related methods */

  const addCertificate = () => {
    if (certificate !== "" && certificateOrganization !== "") {
      const obj = {
        certificate,
        certificateOrganization,
      };
      setCertifications([...certifications, obj]);
      // certifications.push(obj);
      setCertificate("");
      setCertificateOrganization("");
    }
  };

  const removeCertificate = (idx) => () => {
    const copyArr = [...certifications];
    copyArr.splice(idx, 1);
    setCertifications([...copyArr]);
  };

  const renderCertifications = () => {
    const list = certifications.map((cert, idx) => (
      <span key={idx} className={classes.keyword}>
        <p>{cert.certificate}</p>

        <Cancel
          className={classes.cancelSelection}
          onClick={removeCertificate(idx)}
        />
      </span>
    ));
    return list;
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <div className={classes.formControl}>
          <label className={classes.formLabel}>Summary</label>
          <textarea
            type={"text"}
            className={classes.formInput}
            rows="6"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          />
        </div>

        {/*first row container*/}
        <div className={classes.rowInputsContainer}>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Your Name</label>
              <input
                type={"text"}
                className={classes.formInput}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Company Name</label>
              <input
                type={"text"}
                className={classes.formInput}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/**Second Row COntainer */}
        <div className={classes.formControl}>
          <label className={classes.formLabel}>Areas of Expertise</label>
          <div className={classes.selectedMultiValues}>
            <div className={classes.selectedValues}>{renderExpertise()}</div>
            <div
              className={classes.customSelectArrow}
              onClick={() => {
                setOpenExpertiseSelect(!openExpertiseSelect);
              }}
            >
              <Divider orientation="vertical" flexItem />
              {openExpertiseSelect ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </div>
          </div>
          <div
            className={classes.selectOptions}
            ref={expertiseSelectRef}
            style={{ display: openExpertiseSelect ? "block" : "none" }}
          >
            {renderExpertiseOptions()}
          </div>
        </div>
        <div className={classes.formControl}>
          <label className={classes.formLabel}>Skills</label>
          <div className={classes.keywordsContainer}>{renderSkills()}</div>
          {/* <label className={classes.formLabel}>Keywords</label> */}
          <input
            type={"text"}
            className={classes.formInput}
            onKeyUp={handleSkillKeyUp}
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
          />
          <p>
            Hit ENTER after typing in each skill (e.g. DigitalMarketing,
            BIPOC-Owned, Java)
          </p>
        </div>
        <div className={classes.rowInputsContainer}>
          {/* <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Portfolio</label>
              <input
                type={"file"}
                accept="application/pdf"
                style={{ display: "none" }}
                ref={fileRef}
                // onChange={() => setFile(fileRef.current.files[0])}
              />
              <div
                className={classes.formFileInput}
                onClick={() => fileRef.current.click()}
              >
                <p>{file ? file.name : "No File Selected"}</p>
                <AttachFileIcon />
              </div>
            </div>
          </div> */}
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Minimum Budget</label>
              <input
                type={"text"}
                className={classes.formInput}
                value={minimumBudget}
                onChange={(e) => setMinimumBudget(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Hourly Rate</label>
              <input
                type={"text"}
                className={classes.formInput}
                value={hourlyRate}
                onChange={(e) => setHourlyRate(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className={classes.rowInputsContainer}>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Website link</label>
              <input
                type={"text"}
                className={classes.formInput}
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Team Size</label>
              <select
                className={classes.formSelect}
                value={teamSize}
                onChange={(e) => setTeamSize(e.target.value)}
              >
                <option value={""}>Select team size</option>
                <option value={"1-5"}>1 - 5</option>
                <option value={"5-10"}>5 - 10</option>
                <option value={"10-30"}>10 - 30</option>
                <option value={"30-50"}>30 - 50</option>
                <option value={"50-100"}>50 - 100</option>
                <option value={"100+"}>100+</option>
              </select>
            </div>
          </div>
        </div>
        <div
          className={classes.rowInputsContainer}
          style={{ position: "relative" }}
        >
          <div className={classes.rowInput}>
            <div
              className={classes.formControl}
              style={{ position: "absolute", top: 0, left: 0, width: "49%" }}
            >
              <label className={classes.formLabel}>Languages</label>
              <div className={classes.selectedMultiValues}>
                <div className={classes.selectedValues}>
                  {renderLanguages()}
                </div>
                <div
                  className={classes.customSelectArrow}
                  onClick={() => {
                    setOpenLanguagesSelect(!openLanguagesSelect);
                  }}
                >
                  <Divider orientation="vertical" flexItem />
                  {openLanguagesSelect ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </div>
              </div>
              <div
                className={classes.selectOptions}
                ref={languagesSelectRef}
                style={{ display: openLanguagesSelect ? "block" : "none" }}
              >
                {renderLanguagesOptions()}
              </div>
            </div>
          </div>
          <div className={classes.rowInput}>
            <div className={classes.formControl}>
              <label className={classes.formLabel}>Certifications</label>
              <Divider flexItem />
              <div className={classes.keywordsContainer}>
                {renderCertifications()}
              </div>
              <input
                type={"text"}
                className={classes.formInput}
                placeholder="Certificate"
                // onKeyUp={handleKeyUp}
                value={certificate}
                onChange={(e) => setCertificate(e.target.value)}
              />
              <input
                type={"text"}
                className={classes.formInput}
                placeholder={"Certification Organization"}
                // onKeyUp={handleKeyUp}
                value={certificateOrganization}
                onChange={(e) => setCertificateOrganization(e.target.value)}
              />
              <div className={classes.formControl}>
                <button
                  className={classes.submit}
                  style={{ alignSelf: "flex-start" }}
                  onClick={addCertificate}
                >
                  Add Certificate
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.formControl}>
          <button type={"submit"} className={classes.submit}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default withStyles(styles)(BidderProfileForm);
