const DARKBLUE = "#013870";

const fontLarge = {
  fontWeight: "bold",
  fontFamily: "Roboto",
  fontSize: "50px",
  color: DARKBLUE,
};
const fontMedium = {
  fontWeight: "500",
  fontFamily: "Roboto",
  fontSize: "35px",
  color: DARKBLUE,
};
const fontSmall = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "25px",
  color: DARKBLUE,
};
const flexJustifyAlignCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const justifyAlignCenter = {
  alignItems: "center",
  justifyContent: "center",
};
const button = {
  borderRadius: "5px",
  padding: "5px 15px 5px 15px",
  cursor: "pointer",
};

const styles = (theme) => ({
  container: {
    marginTop: "60px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  row: {
    margin: "20px 0px 20px 0px",
    ...flexJustifyAlignCenter,
  },
  rowSpaceBetween: {
    margin: "20px 0px 20px 0px",
    ...flexJustifyAlignCenter,
    justifyContent: "space-between",
  },
  content: {
    margin: "10px 0px 10px 0px",
    ...flexJustifyAlignCenter,
    flexDirection: "column",
  },
  largeFont: {
    ...fontLarge,
    margin: "10px 0px 10px 0px",
  },
  mediumFont: {
    ...fontMedium,
    margin: "10px 0px 10px 0px",
  },
  smallFont: {
    ...fontSmall,
    margin: "10px 0px 10px 0px",
  },
  signUp: {
    ...button,
    ...fontSmall,
    backgroundColor: "green",
    color: "white",
  },
  signIn: {
    ...button,
    ...fontSmall,
    backgroundColor: "orange",
    color: "white",
    marginLeft: "20px",
  },
  footer: {
    backgroundColor: DARKBLUE,
    width: "100%",
    "& *": {
      color: "white",
    },
    ...flexJustifyAlignCenter,
    flexDirection: "column",
  },
  footerContainer: {
    width: "80%",
  },
});
export default styles;
