const styles = (theme) => ({
  container: {
    marginTop: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    marginLeft: "15%",
    borderRadius: "5px",
    boxShadow: "rgba(1, 56, 112, 0.3) 0px 7px 10px 2px",
    padding: "5px",
    margin: "5px",
  },
  settingsCard: {
    margin: "10px",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "rgba(1, 56, 112, 0.3) 0px 7px 10px 2px",
  },
  row: {
    margin: "15px 5px 15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  inputLabel: {
    marginBottom: "5px",
  },

  inputValue: {
    height: "30px",
  },
  saveButton: {
    padding: "10px",
    color: "white",
    backgroundColor: "green",
    borderRadius: "5px",
    width: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "lightGreen",
    },
  },
});
export default styles;
