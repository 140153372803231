import { withStyles } from "@mui/styles";
import styles from "../../resources/styles/helpers-styles/SignIn";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authenticateUser, registerUser } from "../../store/auth/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function SignUp(props) {
  const { classes } = props;

  const [allCountries, setAllCountries] = useState([]);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [servicePackage, setServicePackage] = useState("entry");
  const [signUpAs, setSignUpAs] = useState("bdr");
  const [dataPrivacy, setDataPrivacy] = useState(false);
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [payment, setPayment] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);

  const disptach = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const authError = useSelector((state) => state.auth.isError);
  const authErrorMsg = useSelector((state) => state.auth.errorMessage);
  useEffect(() => {
    if (Object.keys(user).length) {
      navigate("/marketplace");
    }
  }, [user]);
  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((res) => setAllCountries(res.data));
  }, []);
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div
        className={classes.content}
        style={{ height: "80%", overflowX: "auto" }}
      >
        <div className={classes.row}>
          <div className={classes.headingTextBig}>Welcome!</div>
        </div>
        <div className={classes.row}>
          <div className={classes.headingTextMedium}>
            Please fill in the form to sign up!
          </div>
        </div>
        <div className={classes.row}>
          <div
            className={classes.headingTextMedium}
            style={{ color: "red", fontSize: "small" }}
          >
            {authError ? authErrorMsg?.message : ""}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Sign up as</div>
          <div className={classes.signUpAsContainer}>
            <label>
              <input
                type="radio"
                value="bdr"
                checked={signUpAs === "bdr"}
                onChange={() => {
                  setSignUpAs("bdr");
                }}
              />
              Bidder
            </label>
            <label>
              <input
                type="radio"
                value="issur"
                checked={signUpAs === "issur"}
                onChange={() => {
                  setSignUpAs("issur");
                }}
              />
              Issuer
            </label>
          </div>
        </div>{" "}
        <div className={classes.row}>
          <div className={classes.inputLabel}>Service Package</div>
          <select
            onChange={(e) => {
              setServicePackage(e.target.value);
            }}
            value={servicePackage}
            className={classes.inputValue}
          >
            <option selected value={"entry"}>
              Entry
            </option>
            <option value={"business"}>Business</option>
            <option value={"enterprise"}>Enterprise</option>
          </select>
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>First Name</div>
          <input
            type={"text"}
            onChange={(e) => {
              setFirstname(e.target.value);
            }}
            value={firstname}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Last Name</div>
          <input
            type={"text"}
            onChange={(e) => {
              setLastname(e.target.value);
            }}
            value={lastname}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Email Address</div>
          <input
            type={"text"}
            onChange={(e) => {
              setEmailAddress(e.target.value);
            }}
            value={emailAddress}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Password</div>
          <input
            type={"password"}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Re-enter Password</div>
          <input
            type={"password"}
            onChange={(e) => {
              setReEnterPassword(e.target.value);
            }}
            value={reEnterPassword}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Country</div>
          <select
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            value={country}
            className={classes.inputValue}
          >
            {allCountries.map((country) => {
              return (
                <option value={country.name.common}>
                  {country.name.common}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>City</div>
          <input
            type={"text"}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            value={city}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Street</div>
          <input
            type={"text"}
            onChange={(e) => {
              setStreet(e.target.value);
            }}
            value={street}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Zip code</div>
          <input
            type={"text"}
            onChange={(e) => {
              setZipCode(e.target.value);
            }}
            value={zipCode}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>Company</div>
          <input
            type={"text"}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
            value={company}
            className={classes.inputValue}
          />
        </div>
        <div className={classes.rowShort}>
          <input
            type={"checkbox"}
            onChange={(e) => {
              setPayment(!payment);
            }}
            value={payment}
            className={classes.inputValueMargin}
          />
          <div className={classes.inputLabelPrivacy}>Pay on Invoice?</div>
        </div>
        <div className={classes.rowShort}>
          <input
            type={"checkbox"}
            onChange={(e) => {
              setEmailNotifications(!emailNotifications);
            }}
            value={emailNotifications}
            className={classes.inputValueMargin}
          />
          <div className={classes.inputLabelPrivacy}>
            Would you like us to send you email notifications?
          </div>
        </div>
        <div className={classes.rowShort}>
          <input
            type={"checkbox"}
            onChange={(e) => {
              setDataPrivacy(!dataPrivacy);
            }}
            value={password}
            className={classes.inputValueMargin}
          />
          <div className={classes.inputLabelPrivacy}>
            Do you agree with our GDPR/ data privacy policy?
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.LoginButtonContainer}>
            <div
              className={
                dataPrivacy ? classes.LoginButton : classes.LoginButtonDisabled
              }
              onClick={() => {
                if (dataPrivacy)
                  disptach(
                    registerUser(
                      emailAddress,
                      password,
                      firstname,
                      lastname,
                      firstname,
                      "+92 0000000",
                      "",
                      reEnterPassword,
                      signUpAs,
                      servicePackage,
                      country,
                      city,
                      street,
                      zipCode,
                      company,
                      payment,
                      emailNotifications
                    )
                  );
              }}
            >
              Signup
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <div
            className={classes.alreadyAMember}
            onClick={() => {
              navigate("/signin");
            }}
          >
            Already a member?
          </div>
        </div>
      </div>
    </div>
  );
}
export default withStyles(styles)(SignUp);
