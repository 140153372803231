const styles = (theme) => ({
  root: {},
  tabOption: {
    "&.Mui-selected": {
      color: "#01e08f !important",
      borderBottomColor: "#01e08f !important",
    },
  },
});

export default styles;
