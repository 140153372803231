const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    flexWrap: "wrap",
    "& div": {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  loading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default styles;
