import { makeStyles } from "@mui/styles";
import { getPlanIcon } from "../../services/importDynamicIcons";

import DoneIcon from "@mui/icons-material/Done";

import config from "../../config.json";

import StripeCheckout from "react-stripe-checkout";
import { useState } from "react";
import axios from "axios";
import configData from "../../config.json";

import processingPayment from "../../resources/design-icons/processingPayment.gif";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authenticateAlreadySignedInUser } from "../../store/auth/auth";

function PlanCard(props) {
  const { plan, changeSelection, selected, id } = props;

  const font = {
    fontFamily: "Roboto",
    letterSpacing: "0.5px",
  };

  const fontHeavy = {
    fontWeight: "800",
  };

  const fontSizeXLarge = {
    fontSize: "43px",
  };

  const fontSizeLarge = {
    fontSize: "25px",
  };

  const fontSizeMedium = {
    fontSize: "18px",
  };

  const fontSizeSmall = {
    fontSize: "12px",
  };

  const dynamicStyles = makeStyles({
    // headerText: {
    //   fontFamily: "Roboto",
    //   fontWeight: "900",
    //   fontSize: "35px",
    //   color: headerTextColor,
    // },
    container: {
      margin: "10px 5px 10px 5px",
      color: "white !important",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
    },
    iconContainer: {
      position: "relative",
      top: "35px",
    },
    contentContainerInActive: {
      backgroundColor: plan.backgroundColorTile,
      width: "300px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      // color: "white",
      minHeight: "460px",
      border: `1px solid ${plan.borderColor}`,
      boxShadow: "-2px 7px 25px -11px rgba(0,0,0,0.75)",
    },
    contentContainerActive: {
      backgroundColor: plan.backgroundColorTileAlt,
      color: plan.colorAlt,
      width: "300px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",

      minHeight: "460px",
      border: `1px solid ${plan.borderColor}`,
      boxShadow: "-2px 7px 25px -11px rgba(0,0,0,0.75)",
    },
    title: {
      ...font,
      ...fontSizeLarge,

      marginTop: "60px",
    },
    price: {
      ...font,
      ...fontSizeXLarge,

      marginTop: "20px",
    },
    planFeaturesContainer: {
      width: "75%",
    },
    planFeatures: {
      ...font,
      ...fontSizeSmall,
      ...fontHeavy,

      marginTop: "35px",
    },
    featureContainer: {
      ...font,
      ...fontSizeSmall,

      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginTop: "10px",
    },
    featureText: {
      display: "flex",
      alignItems: "center",
    },
    featureValue: {
      ...fontHeavy,
    },
    description: {
      ...font,
      ...fontSizeSmall,

      lineHeight: "25px",
      marginTop: "40px",
      display: "flex",
      width: "70%",
    },
    buttonInActive: {
      ...font,
      border: `1px solid ${plan.borderColor}`,
      backgroundColor: plan.buttonSubmitColorBackground,
      color: plan.buttonSubmitColor,
      width: "99.5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "15px 0px 15px 0px",
      borderTop: "1px solid white",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      cursor: "pointer",
      boxShadow: "-2px 7px 25px -11px rgba(0,0,0,0.75)",
    },
    buttonActive: {
      ...font,
      border: `1px solid ${plan.borderColor}`,
      backgroundColor: plan.buttonSubmitColorBackgroundAlt,
      color: plan.buttonSubmitColorAlt,
      width: "99.5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "15px 0px 15px 0px",
      borderTop: "1px solid white",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      cursor: "pointer",
      boxShadow: "-2px 7px 25px -11px rgba(0,0,0,0.75)",
    },
    paymentContainer: {
      position: "absolute",
      opacity: "90%",
      top: 0,
      zIndex: 3,
      width: "100%",
      height: "960px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    paymentImage: {
      width: "50%",
      height: "",
    },
  });

  const dynamicClasses = dynamicStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isHandlingPayment, setIsHandlingPayment] = useState(false);
  const [handlingPaymentMsg, setHandlingPaymentMsg] = useState("");
  // const handlePlanClick = async () => {
  //   const stripePromise = await loadStripe(config.stripeKey);
  // };
  const handleToken = (stripeData) => {
    setIsHandlingPayment(true);
    let token = "bearer " + localStorage.getItem("token");

    axios
      .post(
        `${configData.urls.baseURL}/payments/`,
        {
          token: stripeData.id,
          paymentPlan: id,
        },
        {
          headers: { authorization: token },
        }
      )
      .then((res) => {
        setHandlingPaymentMsg("Success");
        setTimeout(() => {
          setIsHandlingPayment(false);
          dispatch(
            authenticateAlreadySignedInUser(localStorage.getItem("token"))
          );
          navigate("/marketplace");
        }, 5000);
      })
      .catch((err) => {
        setIsHandlingPayment(false);
        setHandlingPaymentMsg(err);
      });
  };

  return (
    <div
      className={dynamicClasses.container}
      onClick={() => {
        changeSelection(id);
      }}
    >
      {isHandlingPayment ? (
        <div className={dynamicClasses.paymentContainer}>
          <div>Processing Payment {handlingPaymentMsg}</div>
          <img
            className={dynamicClasses.paymentImage}
            src={processingPayment}
            alt={"Processing Payment"}
          />
        </div>
      ) : (
        <></>
      )}
      <div className={dynamicClasses.iconContainer}>
        <img alt={plan.icon} src={getPlanIcon(plan.icon)} />
      </div>
      <div
        className={
          id === selected
            ? dynamicClasses.contentContainerActive
            : dynamicClasses.contentContainerInActive
        }
      >
        <div className={dynamicClasses.title}>{plan.title}</div>
        <div className={dynamicClasses.price}>
          {plan.priceCurrency}
          {plan.priceValue}
        </div>
        <div className={dynamicClasses.planFeaturesContainer}>
          <div className={dynamicClasses.planFeatures}>Plan Features</div>
          {plan.planFeatures.map((plan) => {
            return (
              <div className={dynamicClasses.featureContainer}>
                <div className={dynamicClasses.featureText}>
                  <DoneIcon color="success" style={{ marginRight: "5px" }} />
                  {plan.text}
                </div>
                <div className={dynamicClasses.featureValue}>{plan.value}</div>
              </div>
            );
          })}
        </div>
        <div className={dynamicClasses.description}>{plan.description}</div>
      </div>
      {/* <div
        className={
          plan.id === selected
            ? dynamicClasses.buttonActive
            : dynamicClasses.buttonInActive
        }
        onClick={() => {
          handlePlanClick();
        }}
      >
        {plan.buttonSubmitText}
        <StripeCheckout
          stripeKey={config.stripeKey}
          token={handleToken}
          name="buy"
        ></StripeCheckout>
      </div> */}
      <div
        className={
          id === selected
            ? dynamicClasses.buttonActive
            : dynamicClasses.buttonInActive
        }
      >
        <StripeCheckout
          stripeKey={config.stripeKey}
          token={handleToken}
          name={plan.title}
        >
          {plan.buttonSubmitText}
        </StripeCheckout>
      </div>
    </div>
  );
}
export default PlanCard;
