import { Backdrop, CircularProgress } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkBidder, checkIssuer } from "../../services/checkRole";
import { updateProfile } from "../../store/auth/auth";
import BidderProfileForm from "../helpers/BidderProfileForm";
import IssuerProfileForm from "../helpers/IssuerProfileForm";
import styles from "./../../resources/styles/views-styles/ProfileSettingStyles";

function ProfileSetting(props) {
  const { classes } = props;

  const [about, setAbout] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [industry, setIndustry] = useState("");
  const [mission, setMission] = useState("");
  const [organizationSize, setOrganizationSize] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");

  /**Bidder Profile Form States */
  const [firstName, setFirstName] = useState("");
  const [summary, setSummary] = useState("");
  const [expertise, setExpertise] = useState("");
  const [skills, setSkills] = useState("");
  const [minimumBudget, setMinimumBudget] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [certifications, setCertifications] = useState([]);
  const [languages, setLanguages] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const auth = useSelector((state) => state.auth);
  const authLoading = useSelector((state) => state.auth.loading);

  const initializeProfileStates = (profile) => {
    if (!profile || !Object.keys(profile).length) return;
    setAbout(profile?.about);
    setCompanyName(profile?.company);
    setWebsite(profile?.website);
    setOrganizationType(profile?.organizationType);
    setIndustry(profile?.industry);
    setMission(profile?.mission);
    setOrganizationSize(profile?.organizationSize);
    setAdditionalInformation(profile?.additionalInformation);
    /**Bidder Props */
    setSummary(profile?.summary);
    setFirstName(profile?.firstName);
    setCompanyName(profile?.companyName);
    setWebsite(profile?.websiteLink);
    setExpertise(profile?.areasOfExpertise);
    setSkills(profile?.skills);
    setMinimumBudget(profile?.budgetMinimum);
    setHourlyRate(profile?.hourlyRate);
    setTeamSize(profile?.teamSize);
    setLanguages(profile?.languages);
    setCertifications(profile?.certifications);
  };

  useEffect(() => {
    initializeProfileStates(user?.profile);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const profile = {
      about,
      company: companyName,
      website,
      organizationType,
      industry,
      mission,
      organizationSize,
      additionalInformation,
    };
    dispatch(updateProfile(user.id, profile));
  };

  const handleBidderProfileSubmit = (e) => {
    e.preventDefault();
    const profile = {
      summary,
      companyName: companyName,
      websiteLink: website,
      areasOfExpertise: expertise,
      skills,
      budgetMinimum: minimumBudget,
      hourlyRate,
      teamSize,
      languages,
      certifications,
    };
    dispatch(updateProfile(user.id, profile));
  };
  return (
    <div className={classes.root}>
      <Backdrop open={authLoading}>
        <CircularProgress />
      </Backdrop>
      {checkIssuer(user?.role?.shortCode) && auth.loggedIn ? (
        <IssuerProfileForm
          handleSubmit={handleSubmit}
          values={{
            about,
            setAbout,
            companyName,
            setCompanyName,
            website,
            setWebsite,
            organizationType,
            setOrganizationType,
            industry,
            setIndustry,
            mission,
            setMission,
            organizationSize,
            setOrganizationSize,
            additionalInformation,
            setAdditionalInformation,
          }}
        />
      ) : checkBidder(user?.role?.shortCode) && auth.loggedIn ? (
        <BidderProfileForm
          handleSubmit={handleBidderProfileSubmit}
          values={{
            summary,
            setSummary,
            firstName,
            setFirstName,
            companyName,
            setCompanyName,
            website,
            setWebsite,
            expertise,
            setExpertise,
            skills,
            setSkills,
            minimumBudget,
            setMinimumBudget,
            hourlyRate,
            setHourlyRate,
            teamSize,
            setTeamSize,
            languages,
            setLanguages,
            certifications,
            setCertifications,
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default withStyles(styles)(ProfileSetting);
